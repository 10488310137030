import styled from 'styled-components'

const Card = styled.div`
  background-color: ${({ theme }) => `${theme.colours.snow}`};
  border-color: ${({ theme }) => theme.colours.steel};
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
`

export default Card
