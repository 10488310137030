import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { Field } from 'react-final-form'
import Typography from './Typography'

const Multiselect = ({ name, label, options, required }) => {
  return (
    <Field name={name} required={required}>
      {({ input: { name, onChange, value, ...restInput }, meta, ...rest }) => (
        <FormControl fullWidth style={{ marginBottom: '24px' }}>
          <InputLabel id="multiple-select-label">
            <Typography variant="label">{label}</Typography>
          </InputLabel>
          <Select
            {...rest}
            name={name}
            // helperText={meta.touched ? meta.error : undefined}
            // error={meta.error && meta.touched}
            inputProps={restInput}
            onChange={onChange}
            value={value}
            labelId="multiple-name-label"
            id="multiple-select"
            multiple
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
            meta.touched && (
              <Typography
                variant="caption"
                colour="ruby"
                className="helper-text"
              >
                {meta.error || meta.submitError}
              </Typography>
            )}
        </FormControl>
      )}
    </Field>
  )
}

export default Multiselect
