import React from 'react'
import Cookies from 'js-cookie'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import Loading from '../components/Loading'

const GET_USER_ORGANISATIONS = gql`
  query getUser {
    getUser {
      id
      organisations {
        id
      }
    }
  }
`

export default function ChooseOrganisation({ history }) {
  const { loading, error, data } = useQuery(GET_USER_ORGANISATIONS)

  if (error || !Cookies.get('dd')) return <Redirect to="/login" />

  //check if organisation has already been set
  if (Cookies.get('ddorg')) history.push('/')

  // organisation hasn't been set, get the list of organisations from the user

  if (loading) return <Loading />

  const { organisations } = data.getUser

  //if the user is only at one organisation, set the cookie and redirect automatically
  if (organisations.length === 1) {
    //this person only belongs to one organisation, send them straight to the dashboard and set their organisation cookie as this one
    Cookies.set('ddorg', organisations[0].id)
    history.push('/')
  }

  return <p> Choose Organsiation Page </p>
}
