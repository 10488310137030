import React, { useContext } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { mdiClose, mdiPlusCircle } from '@mdi/js'
import Icon from '@mdi/react'
import styled, { ThemeContext } from 'styled-components'
import { Link } from 'react-router-dom'

import Wizard, { Page } from '../components/Wizard'
import Spacing from '../components/Spacing'
import Input from '../components/Input'
import Select from '../components/Select'
import Typography from '../components/Typography'
import FlexBox from '../components/FlexBox'
import IconButton from '../components/IconButton'
import Button from '../components/Button'
import DropdownLookup from '../components/DropdownLookup'
import AddressInput from '../components/AddressInput'

import WizardComplete from './../assets/images/wizard-complete.svg'

import { getCountryNames, validatePhoneNumber } from './../util/functions'
import { OrganisationContext } from './../util/PageWrapper'

const RemoveRow = styled.div`
  margin-bottom: 29px;
  display: flex;
`

const CREATE_PERSON = gql`
  mutation createPerson(
    $title: String!
    $firstName: String!
    $lastName: String!
    $phoneNumbers: [JSONObject]
    $emailAddresses: [JSONObject]
    $addressLine1: String
    $addressLine2: String
    $city: String
    $county: String
    $postcode: String
    $addressCountry: String
  ) {
    createPerson(
      title: $title
      firstName: $firstName
      lastName: $lastName
      phoneNumbers: $phoneNumbers
      emailAddresses: $emailAddresses
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      county: $county
      postcode: $postcode
      addressCountry: $addressCountry
    ) {
      id
      fullName
    }
  }
`

export default function NewPerson({ hideModal, onComplete }) {
  const [createPerson, { loading, data }] = useMutation(CREATE_PERSON)

  const countriesList = getCountryNames()

  //format countries list for dropdown
  const formattedCountryList = countriesList.map(({ code, name }) => ({
    value: code,
    label: name,
  }))

  const {
    colours: { brand },
  } = useContext(ThemeContext)

  const { defaultPhoneCountry, defaultAddressCountry } =
    useContext(OrganisationContext)

  return (
    <Container>
      <Row justifyContent="center">
        <Col xl={9}>
          <Wizard
            onSubmit={({
              title,
              firstName,
              lastName,
              phoneNumbers,
              emailAddresses,
              addressLine1,
              addressLine2,
              city,
              county,
              postcode,
              addressCountry,
            }) =>
              createPerson({
                variables: {
                  title,
                  firstName,
                  lastName,
                  phoneNumbers,
                  emailAddresses,
                  addressLine1,
                  addressLine2,
                  city,
                  county,
                  postcode,
                  addressCountry,
                },
              })
            }
            mutators={{
              ...arrayMutators,
            }}
            loading={loading}
            name="New Person"
            initialValues={{
              phoneNumbers: [{ phoneCountryCode: defaultPhoneCountry }],
              emailAddresses: [{ email: null }],
              addressCountry: defaultAddressCountry,
            }}
            cancelFunction={hideModal}
          >
            <Page title="Name">
              <Typography variant="h4">Key Details</Typography>
              <Spacing multiplier={3} />
              <Select
                name="title"
                label="Title"
                required
                small
                options={[
                  { value: 'Miss', label: 'Miss' },
                  { value: 'Mrs', label: 'Mrs' },
                  { value: 'Ms', label: 'Ms' },
                  { value: 'Dr', label: 'Dr' },
                  { value: 'Mr', label: 'Mr' },
                ]}
              />
              <Input
                name="firstName"
                required
                label="First Name"
                type="text"
                fullwidth
              />
              <Input
                name="lastName"
                required
                label="Last Name"
                type="text"
                fullwidth
              />
            </Page>
            <Page
              title="Personal Details"
              validate={({ phoneNumbers, emailAddresses }) => {
                const errors = {}

                const enteredNumbers = phoneNumbers.map(
                  ({ phoneNumber }) => phoneNumber
                )

                const duplicateNumbers = enteredNumbers.reduce(
                  (acc, v, i, arr) =>
                    arr.indexOf(v) !== i && acc.indexOf(v) === -1
                      ? acc.concat(v)
                      : acc,
                  []
                ) // https://stackoverflow.com/questions/840781/get-all-non-unique-values-i-e-duplicate-more-than-one-occurrence-in-an-array

                errors.phoneNumbers = phoneNumbers.map(
                  ({ phoneNumber, phoneCountryCode }) => {
                    if (phoneCountryCode) {
                      //check if phone number has already been enetered
                      if (duplicateNumbers.includes(phoneNumber)) {
                        return {
                          phoneNumber: 'Duplicate number',
                        }
                      }
                      return {
                        phoneNumber: validatePhoneNumber(
                          phoneNumber,
                          phoneCountryCode
                        ),
                      }
                    } else if (phoneNumber) {
                      //number entered but no country selected
                      return {
                        phoneCountryCode: 'Invalid Country',
                      }
                    }

                    return undefined
                  }
                )

                //check emails haven't been entered twice
                const enteredEmails = emailAddresses.map(({ email }) => email)

                const duplicateEmails = enteredEmails.reduce(
                  (acc, v, i, arr) =>
                    arr.indexOf(v) !== i && acc.indexOf(v) === -1
                      ? acc.concat(v)
                      : acc,
                  []
                ) // https://stackoverflow.com/questions/840781/get-all-non-unique-values-i-e-duplicate-more-than-one-occurrence-in-an-array

                errors.emailAddresses = emailAddresses.map(({ email }) => {
                  //check if email has already been enetered
                  if (email && duplicateEmails.includes(email)) {
                    return {
                      email: 'Duplicate email',
                    }
                  }

                  return undefined
                })

                return errors
              }}
            >
              <Typography variant="h4">Personal Details</Typography>
              <Spacing multiplier={3} />
              <FieldArray name="phoneNumbers">
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => (
                      <FlexBox key={name} alignItems="flex-end">
                        <DropdownLookup
                          name={`${name}.phoneCountryCode`}
                          label={index ? '' : 'Phone Number'}
                          options={formattedCountryList}
                        />
                        <Spacing multiplier={2} />
                        <Input
                          name={`${name}.phoneNumber`}
                          type="number"
                          fullwidth
                        />

                        {fields.length > 1 && (
                          <RemoveRow>
                            <Spacing multiplier={2} />
                            <IconButton onClick={() => fields.remove(index)}>
                              <Icon
                                path={mdiClose}
                                title="Delete Row"
                                size="24px"
                                color={brand}
                              />
                            </IconButton>
                          </RemoveRow>
                        )}
                      </FlexBox>
                    ))}
                    <FlexBox
                      inline
                      onClick={() =>
                        fields.push({ phoneCountryCode: defaultPhoneCountry })
                      }
                    >
                      <Icon
                        path={mdiPlusCircle}
                        title="Delete Row"
                        size="20px"
                        color={brand}
                      />
                      <Spacing multiplier={1} />
                      <Typography variant="bodySmall" colour="brand">
                        Add another number
                      </Typography>
                    </FlexBox>
                  </>
                )}
              </FieldArray>
              <Spacing multiplier={3} />
              <FieldArray name="emailAddresses">
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => (
                      <FlexBox key={name} alignItems="flex-end">
                        <Input
                          name={`${name}.email`}
                          type="email"
                          fullwidth
                          label={index ? '' : 'Email Address'}
                        />

                        {fields.length > 1 && (
                          <RemoveRow>
                            <Spacing multiplier={2} />
                            <IconButton onClick={() => fields.remove(index)}>
                              <Icon
                                path={mdiClose}
                                title="Delete Row"
                                size="24px"
                                color={brand}
                              />
                            </IconButton>
                          </RemoveRow>
                        )}
                      </FlexBox>
                    ))}
                    <FlexBox
                      inline
                      onClick={() => fields.push({ email: null })}
                    >
                      <Icon
                        path={mdiPlusCircle}
                        title="Delete Row"
                        size="20px"
                        color={brand}
                      />
                      <Spacing multiplier={1} />
                      <Typography variant="bodySmall" colour="brand">
                        Add another email
                      </Typography>
                    </FlexBox>
                  </>
                )}
              </FieldArray>
              <Spacing multiplier={4} />
              <Typography variant="h5">Home Address</Typography>
              <Spacing multiplier={3} />
              <AddressInput />
            </Page>
            <Page confirmation>
              <FlexBox flexDirection="column">
                <IconButton
                  className="close-button"
                  onClick={() => {
                    if (onComplete) onComplete()
                    hideModal()
                  }}
                >
                  <Icon
                    path={mdiClose}
                    title="Close"
                    size="24px"
                    color={brand}
                  />
                </IconButton>
                <Spacing multiplier={8} />
                <img
                  src={WizardComplete}
                  alt="Compelte"
                  height="176px"
                  width="100%"
                />
                <Spacing multiplier={4} />
                <Typography variant="h4" center>
                  New person created successfully!
                </Typography>
                <Spacing multiplier={1} />
                <Typography variant="bodySmall" colour="slate" center>
                  {`${data?.createPerson?.fullName} has been added.`}
                </Typography>
                <Spacing multiplier={4} />
                <Button
                  colour="brand"
                  type="button"
                  component={Link}
                  to={`/people/view/${data?.createPerson?.id}`}
                >
                  View Contact
                </Button>
              </FlexBox>
            </Page>
          </Wizard>
        </Col>
      </Row>
    </Container>
  )
}
