import React, { Fragment } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'

import Spacing from './Spacing'
import Typography from './Typography'

export default function Footer() {
  return (
    <Fragment>
      <Container>
        <Row justifyContent="center">
          <Col col xl="4">
            <Typography variant="bodySmall" colour="slate" center>
              &copy; {new Date().getFullYear()} Daffodil Group. All Rights
              Reserved.
            </Typography>
            <Typography
              variant="bodySmall"
              component={Link}
              to="/privacy-policy"
              center
              colour="brand"
            >
              Privacy Policy
            </Typography>
          </Col>
        </Row>
      </Container>
      <Spacing multiplier={9} />
    </Fragment>
  )
}
