import React, { useState, useContext } from 'react'
import { mdiPlusCircle, mdiDotsVertical } from '@mdi/js'
import Icon from '@mdi/react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { format, formatDistance } from 'date-fns'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import Error from '../../components/Error'
import Breadcrumbs from '../../components/Breadcrumbs'
import Button from '../../components/Button'
import Title from '../../components/Title'
import Tabs from '../../components/Tabs'
import Table from '../../components/Table'
import Typography from '../../components/Typography'
import Dropdown from '../../components/Dropdown'
import MenuItem from '../../components/MenuItem'
import IconButton from '../../components/IconButton'
import DeleteLink from './DeleteLink'

const GET_SECURE_SHARE_LINKS = gql`
  query getSecureShareLinks(
    $status: String
    $pageIndex: Int
    $pageSize: Int
    $sortBy: [JSONObject]
  ) {
    getSecureShareLinks(
      status: $status
      pageIndex: $pageIndex
      pageSize: $pageSize
      sortBy: $sortBy
    ) {
      secureShareLinks {
        id
        createdBy {
          id
          fullName
        }
        createdDate
        link
        purpose
        expiryDate
      }
      pageCount
    }
  }
`

export default function SecureShare() {
  const [activeTab, setActiveTab] = useState('Active Links')
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [sortBy, setSortBy] = useState([])
  const themeContext = useContext(ThemeContext)

  const { slate } = themeContext.colours

  const { loading, error, data } = useQuery(GET_SECURE_SHARE_LINKS, {
    variables: {
      status: activeTab === 'Active Links' ? 'active' : 'expired',
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
    },
    fetchPolicy: 'cache-and-network',
  })

  let tableData = []

  if (data) {
    tableData = data.getSecureShareLinks.secureShareLinks.map(
      (secureShareDetail) => ({
        createdBy: secureShareDetail.createdBy.fullName,
        createdDate: new Date(secureShareDetail.createdDate),
        link: (
          <Typography
            variant="bodySmall"
            colour="azure"
            component="a"
            href={secureShareDetail.link}
            target="_blank"
          >
            {secureShareDetail.link}
          </Typography>
        ),
        purpose: secureShareDetail.purpose,
        expiryDate: new Date(secureShareDetail.expiryDate),
        actions: (
          <Dropdown anchor="right">
            <IconButton dropdownTrigger>
              <Icon
                path={mdiDotsVertical}
                title="Secure Share Link Settings"
                size="24px"
                color={slate}
              />
            </IconButton>

            <MenuItem
              component={Link}
              to={`/secure-share/share-link/${secureShareDetail.id}`}
            >
              <Typography variant="bodySmall">View Link Details</Typography>
            </MenuItem>
            <DeleteLink
              secureShareId={secureShareDetail.id}
              activeTab={activeTab}
            />
          </Dropdown>
        ),
      })
    )
  }

  return (
    <>
      <Breadcrumbs items={[{ label: 'Secure Share' }]} />
      <Title
        title="Secure Share"
        lite
        button={
          <Button
            colour="brand"
            component={Link}
            to="/secure-share/new-link"
            icon={
              <Icon
                path={mdiPlusCircle}
                title="Add Secure Link"
                size="24px"
                color="white"
              />
            }
          >
            Create New Link
          </Button>
        }
      />
      <Tabs
        tabs={[
          {
            label: 'Active Links',
            active: activeTab === 'Active Links',
            onClick: () => setActiveTab('Active Links'),
          },
          {
            label: 'Expired Links',
            active: activeTab === 'Expired Links',
            onClick: () => setActiveTab('Expired Links'),
          },
        ]}
      />
      <Container>
        <Row justifyContent="center">
          <Col col xl="12">
            {error ? (
              <Error error={error} />
            ) : (
              <Table
                title={
                  activeTab === 'Active Links'
                    ? 'My Active Links'
                    : 'My Expired Links'
                }
                headers={[
                  {
                    Header: 'Created By',
                    accessor: 'createdBy',
                    disableFilters: true,
                  },
                  {
                    Header: 'Created',
                    id: 'createdDate',
                    accessor: (c) => c.createdDate,
                    Cell: (c) => format(new Date(c.cell.value), 'dd LLL y'),
                    disableFilters: true,
                  },
                  {
                    Header: 'Link URL',
                    accessor: 'link',
                    disableFilters: true,
                  },
                  {
                    Header: 'Purpose',
                    accessor: 'purpose',
                    disableFilters: true,
                  },
                  {
                    Header:
                      activeTab === 'Active Links' ? 'Expires' : 'Expired',
                    id: 'expiryDate',
                    accessor: (c) => c.expiryDate,
                    Cell: (c) =>
                      formatDistance(new Date(c.cell.value), new Date()),
                    disableFilters: true,
                  },
                  {
                    accessor: 'actions',
                    disableSortBy: true,
                    disableFilters: true,
                  },
                ]}
                data={tableData}
                loading={loading}
                updatePageIndex={setPageIndex}
                updatePageSize={setPageSize}
                pageSize={pageSize}
                pageIndex={pageIndex}
                sortBy={sortBy}
                updateSortBy={setSortBy}
                pageCount={data?.getSecureShareLinks?.pageCount}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}
