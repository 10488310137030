import React, { useContext } from 'react'
import { UserContext } from '../../util/PageWrapper'
import { Link } from 'react-router-dom'

import { Col, Container, Row } from 'styled-bootstrap-grid'

import Spacing from '../../components/Spacing'
import Typography from '../../components/Typography'
import Card from '../../components/Card'
import CardBody from '../../components/CardBody'
import Breadcrumbs from '../../components/Breadcrumbs'
import Title from '../../components/Title'

export default function AdminSettings() {
  const user = useContext(UserContext)

  if (user.roleId < 5) {
    return null
  }

  return (
    <>
      <Breadcrumbs items={[{ label: 'Admin Settings' }]} />

      <Title title="Admin Settings" lite />

      <Container>
        <Row>
          <Col md={4}>
            <Link to={`/admin-settings/users`}>
              <Card>
                <CardBody>
                  <Spacing multiplier={2} />
                  <Typography variant="h5" center>
                    Users
                  </Typography>
                  <Spacing multiplier={2} />
                </CardBody>
              </Card>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  )
}
