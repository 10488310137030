import React from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'

import FlexBox from './FlexBox'
import Spacing from './Spacing'
import Typography from './Typography'

export default function Title({ title, button, lite }) {
  return (
    <Container>
      <Row justifyContent="center">
        <Col col xl="12">
          <FlexBox justifyContent="space-between">
            <Typography variant="h2">{title}</Typography>
            {!!button && button}
          </FlexBox>
          <Spacing multiplier={lite ? 3 : 5} />
        </Col>
      </Row>
    </Container>
  )
}
