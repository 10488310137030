import React from 'react'
import styled from 'styled-components'

const StyledTypography = styled.div`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : '')};
  font-family: ${({ variant, theme }) => {
    //calculate font family to use
    switch (variant) {
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'button':
      case 'navigation':
      case 'tab':
      default:
        return theme.typography.primary

      case 'bodyLarge':
      case 'bodySmall':
      case 'label':
      case 'caption':
        return theme.typography.secondary
    }
  }};
  color: ${({ theme, variant, colour = 'pitch', onClick, as }) => {
    const isLink = !!onClick || as?.displayName === 'Link'

    if (isLink && colour === 'pitch') {
      colour = 'azure'
    }

    //change default to grey if using a label
    if (variant === 'label' && colour === 'pitch' && !isLink) {
      colour = 'slate'
    }

    return theme.colours[colour]
  }};
  text-decoration: none;
  font-size: ${({ variant }) => {
    //calculate font family to use
    switch (variant) {
      case 'h1':
        return '52px'

      case 'h2':
        return '34px'

      case 'h3':
        return '26px'

      case 'h4':
        return '24px'

      case 'h5':
        return '20px'

      case 'bodyLarge':
      case 'tab':
        return '18px'

      case 'bodySmall':
      case 'navigation':
      default:
        return '16px'

      case 'label':
      case 'caption':
      case 'button':
        return '14px'
    }
  }};
  font-weight: ${({ variant, semiBold }) => {
    //calculate font family to use

    if (semiBold) return '500'

    switch (variant) {
      case 'h1':
      case 'h2':
      case 'h4':
        return '600'

      case 'button':
        return '500'

      case 'h3':
      case 'h5':
      case 'bodyLarge':
      case 'bodySmall':
      case 'label':
      case 'caption':
      case 'navigation':
      case 'tab':
      default:
        return '400'
    }
  }};
  line-height: ${({ variant }) => {
    //calculate font family to use
    switch (variant) {
      case 'h1':
        return '64px'

      case 'h2':
      case 'navigation':
        return '48px'
      case 'tab':
        return '40px'

      case 'h3':
        return '36px'

      case 'h4':
        return '34px'

      case 'h5':
        return '30px'

      case 'bodyLarge':
      default:
        return '28px'

      case 'bodySmall':
      case 'label':
        return '26px'

      case 'caption':
      case 'button':
        return '24px'
    }
  }};
  /* Add text transform for button */
  text-transform: ${({ variant }) => {
    if (variant === 'button') return 'uppercase'
  }};
  /* Add text align center if prop is passed */
  text-align: ${({ center }) => {
    if (center) return 'center'
  }};
  /* Add hover style for navigation */
  &:hover,
  &:active {
    cursor: ${({ onClick, clickable }) => {
      if (!!onClick || clickable) return 'pointer'
    }};
    color: ${({ theme, variant, colour = 'pitch', onClick, as }) => {
      const isLink = !!onClick || as?.displayName === 'Link'

      if (variant === 'label') {
        colour = 'slate'
      }

      if (isLink && variant === 'label') {
        colour = 'brand'
      }

      return theme.colours[colour]
    }};
  }
`

export default function Typography({ children, component, center, ...rest }) {
  //calculate component
  const { variant } = rest
  let tag = component

  if (!tag) {
    switch (variant) {
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'label':
        tag = variant
        break

      case 'bodyLarge':
      case 'bodySmall':
      case 'caption':
      default:
        tag = 'p'
        break

      case 'button':
      case 'navigation':
      case 'tab':
        tag = 'span'
        break
    }
  }

  //center has to be entered like this
  return (
    <StyledTypography as={tag} center={center ? 1 : 0} {...rest}>
      {children}
    </StyledTypography>
  )
}
