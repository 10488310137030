// This file contains useful functions
import { getCountries, parsePhoneNumberFromString } from 'libphonenumber-js/max'

import countries from './../assets/misc/IsoCountries.json'
import addressFields from './../assets/misc/CountryAddressFields.json'
import counties from './../assets/misc/Counties.json'

// Validate Phone Number

export const getCountryNames = () => {
  const countryCodes = getCountries()

  //filter out codes we cant use

  const filteredCountryCodes = countryCodes.filter((code) =>
    Boolean(
      Object.keys(countries).find(
        (countryName) => countries[countryName] === code
      )
    )
  )

  return filteredCountryCodes.map((code) => {
    const name = Object.keys(countries).find(
      (countryName) => countries[countryName] === code
    )

    return { code, name }
  })
}

export const validatePhoneNumber = (number, country) => {
  if (!number) return
  const parsedNumber = parsePhoneNumberFromString(number.toString(), country)

  if (!parsedNumber?.isValid()) return `Invalid number for this country`
}

export const getAddressFields = (country) => {
  if (!country) return []

  if (addressFields[country]) return addressFields[country]

  //We haven't yet built the address fields for this country, show the uk ones by default
  return addressFields['GB']
}

export const getCounties = (country) => {
  if (!country) return []

  if (counties[country]) return counties[country]

  //We haven't got the counties for this country, use the default ones for the uk
  return counties['GB']
}

export const getFormattedStringAddress = (enteredAddress) => {
  if (!enteredAddress) return null

  // get order of address fields
  const fieldOrder = getAddressFields(enteredAddress.country)

  let orderedAddressFields = fieldOrder.map(({ name }) => enteredAddress[name])
  //remove null values
  orderedAddressFields = orderedAddressFields.filter((field) => field)

  // add country to end
  const { name: countryName } = getCountryNames().find(
    ({ code }) => code === enteredAddress.country
  )

  orderedAddressFields.push(countryName)

  //join into a string and return
  return orderedAddressFields.join(', ')
}
