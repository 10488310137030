import React, { useState, useEffect, useContext } from 'react'
import { useFormState } from 'react-final-form'
import { validate as postcodeValidate } from 'postal-codes-js'

import Input from './Input'
import Typography from './Typography'
import FlexBox from './FlexBox'
import DropdownLookup from './DropdownLookup'

import { OrganisationContext } from './../util/PageWrapper'

import {
  getCountryNames,
  getAddressFields,
  getCounties,
} from './../util/functions'

const automaticAddressCountries = ['GB']

export default function AddressInput({ required: addressRequired }) {
  const [isManualEntry, setIsManualEntry] = useState(true)

  // check if postcode lookup is enabled for this organisation
  const { postcodeLookup } = useContext(OrganisationContext)

  const {
    values: { addressCountry },
  } = useFormState({ subscription: { values: true } })

  //If this address is allowed to have an automatic retry, set manual rety to false to enable to lookup
  useEffect(() => {
    if (automaticAddressCountries.includes(addressCountry) && postcodeLookup)
      setIsManualEntry(false)
  }, [addressCountry, postcodeLookup])

  return (
    <>
      <DropdownLookup
        name="addressCountry"
        label="Country"
        required={addressRequired}
        options={getCountryNames().map(({ code, name }) => ({
          value: code,
          label: name,
        }))}
      />

      {getAddressFields(addressCountry).map(
        ({ fieldType, name, label, fullWidth, required: fieldRequired }) => {
          switch (fieldType) {
            case 'dropdownLookup':
              if (!isManualEntry) return null
              return (
                <DropdownLookup
                  key={name}
                  name={name}
                  label={label}
                  fullwidth={fullWidth}
                  required={fieldRequired && addressRequired}
                  options={getCounties(addressCountry).map((countyName) => ({
                    value: countyName,
                    label: countyName,
                  }))}
                />
              )

            case 'postcode':
              return (
                <FlexBox key={name}>
                  <Input
                    name={name}
                    label={label}
                    type={fieldType}
                    fullwidth={fullWidth}
                    required={fieldRequired && addressRequired}
                    validate={(value) => {
                      if (
                        value &&
                        postcodeValidate(addressCountry, value) !== true
                      )
                        return 'Invalid Postcode'
                    }}
                  />
                </FlexBox>
              )

            default:
              if (!isManualEntry) return null
              return (
                <Input
                  key={name}
                  name={name}
                  label={label}
                  type={fieldType}
                  fullwidth={fullWidth}
                  required={fieldRequired && addressRequired}
                />
              )
          }
        }
      )}
      {automaticAddressCountries.includes(addressCountry) && postcodeLookup && (
        <Typography
          variant="bodySmall"
          colour="brand"
          onClick={() => {
            setIsManualEntry(!isManualEntry)
          }}
        >
          {isManualEntry
            ? 'Find address by postcode '
            : 'Enter address manually'}
        </Typography>
      )}
    </>
  )
}
