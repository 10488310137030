import React from 'react'
import styled from 'styled-components'

import defaultAvatar from './../assets/images/default-avatar.png'

const StyledUserIcon = styled.img`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  border-radius: 50%;
`

export default function UserIcon({ src = defaultAvatar, ...props }) {
  //if src is null, set it to default icon

  if (src == null) src = defaultAvatar

  return <StyledUserIcon src={src} {...props} />
}
