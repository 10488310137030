import React, { Fragment, useContext } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { format } from 'date-fns'
import styled, { ThemeContext } from 'styled-components'
import { mdiEmail, mdiInformationOutline } from '@mdi/js'
import Icon from '@mdi/react'

import Card from './Card'
import CardBody from './CardBody'
import Typography from './Typography'
import Spacing from './Spacing'
import FlexBox from './FlexBox'
import CardHeader from './CardHeader'
import Divider from './Divider'

import EmptyTable from './../assets/images/empty-table.svg'

const VerticalDivider = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${({ theme }) => `${theme.colours.mirror}`};
  margin: 0 8px;
  align-self: center;
`

const ActivityWrapper = styled.div`
  width: 100%;
`

export default function Activity({ emails, activities }) {
  const themeContext = useContext(ThemeContext)

  const { mirror } = themeContext.colours

  // format emails for showing in activity
  const formattedEmails = emails.map((email) => ({
    id: email.id,
    type: 'Email',
    user: email.createdBy.fullName,
    ip: email.ip,
    time: email.sentTime ? email.sentTime : email.createdDate,
    icon: <Icon path={mdiEmail} title="Email" size="24px" color={mirror} />,
    details: (
      <Fragment>
        <Typography variant="bodySmall">{`Email ${email.status}`}</Typography>
        <Spacing multiplier={1} />
        <Typography variant="label">Subject</Typography>
        <Typography variant="bodySmall">{email.subject}</Typography>
        <Spacing multiplier={1} />
        <Typography variant="label">To</Typography>
        <Typography variant="bodySmall">{email.targetEmail}</Typography>
        {!!email.notes && (
          <>
            <Spacing multiplier={1} />
            <Typography variant="label">Notes</Typography>
            <Typography variant="bodySmall">{email.notes}</Typography>
          </>
        )}
      </Fragment>
    ),
  }))

  const formattedActivities = activities.map((activity) => ({
    id: activity.id,
    type: 'Activity',
    user: activity?.user?.fullName ? activity.user.fullName : 'Unknown User',
    ip: activity.ip,
    time: activity.time,
    icon: (
      <Icon
        path={mdiInformationOutline}
        title="Info"
        size="24px"
        color={mirror}
      />
    ),
    details: <Typography variant="bodySmall">{activity.content}</Typography>,
  }))

  const activityData = formattedEmails.concat(formattedActivities)

  activityData.sort((a, b) => new Date(b.time) - new Date(a.time))

  return (
    <Container>
      <Row justifyContent="center">
        <Col col xl="12">
          <Spacing multiplier={5} />
          <Card>
            <CardHeader>
              <Typography variant="h4">Activity</Typography>
            </CardHeader>
            <CardBody>
              {activityData.length ? (
                <Fragment>
                  {activityData.map((activity, index) => (
                    <Fragment key={activity.id}>
                      {!!index && (
                        <Fragment>
                          <Divider />
                          <Spacing multiplier={3} />
                        </Fragment>
                      )}
                      <FlexBox alignItems="flex-start">
                        {activity.icon}
                        <Spacing multiplier={1} />
                        <ActivityWrapper>
                          <FlexBox justifyContent="space-between">
                            <FlexBox>
                              <Typography variant="bodySmall" colour="brand">
                                {activity.user}
                              </Typography>
                              <Fragment>
                                <VerticalDivider />
                                <Typography variant="bodySmall" colour="slate">
                                  {activity.ip}
                                </Typography>
                              </Fragment>
                            </FlexBox>
                            <Typography variant="bodySmall" colour="slate">
                              {format(
                                new Date(activity.time),
                                "dd LLL y 'at' H:mm"
                              )}
                            </Typography>
                          </FlexBox>
                          <Spacing multiplier={1} />
                          {activity.details}
                          {index !== activityData.length - 1 && (
                            <Spacing multiplier={3} />
                          )}
                        </ActivityWrapper>
                      </FlexBox>
                    </Fragment>
                  ))}
                </Fragment>
              ) : (
                <FlexBox flexDirection="column">
                  <Spacing multiplier={10} />
                  <img
                    src={EmptyTable}
                    alt="Empty Table"
                    height="158px"
                    width="100%"
                  />
                  <Spacing multiplier={4} />
                  <Typography variant="h5" center>
                    Nothing to see here
                  </Typography>
                  <Spacing multiplier={3} />
                  <Typography variant="bodySmall" colour="slate" center>
                    There hasn't been any activity yet.
                  </Typography>
                  <Spacing multiplier={8} />
                </FlexBox>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
