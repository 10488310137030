import styled from 'styled-components'
import { rgba } from 'polished'

const Divider = styled.hr`
  background-color: ${({ theme }) => {
    return rgba(theme.colours.mirror, 0.4)
  }};
  width: 100%;
  height: 1px;
  min-height: 1px;
  margin: 0;
  border: none;
`

export default Divider
