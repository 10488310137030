import React, { createContext } from 'react'
import styled from 'styled-components'
import { ModalProvider } from 'react-modal-hook'

import Spacing from '../components/Spacing'
import Header from './../components/Header'
import Footer from './../components/Footer'

//create site wrapped component

export const UserContext = createContext()
export const OrganisationContext = createContext()

const SiteBody = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const SiteContent = styled.div`
  flex: 1;
`

export default function PageWrapper({ children, user, organisation }) {
  return (
    <UserContext.Provider value={user ? { ...user } : user}>
      <OrganisationContext.Provider value={{ ...organisation }}>
        <ModalProvider>
          <SiteBody>
            <Header />
            <SiteContent>
              {children}
              <Spacing multiplier={6} />
            </SiteContent>
            <Footer />
          </SiteBody>
        </ModalProvider>
      </OrganisationContext.Provider>
    </UserContext.Provider>
  )
}
