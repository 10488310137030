import { DatePicker } from '@material-ui/pickers'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'

const DatePickerField = ({ input, meta, label, required }) => {
  return (
    <DatePicker
      format="dd/MM/yyyy"
      name={input.name}
      onChange={input.onChange}
      value={input.value ? input.value : null}
      onBlur={input.onBlur}
      // fullWidth
      required={required ? required : false}
      disablePast={false}
      autoOk={true}
      disabled={false}
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : ''}
      label={label}
      leftArrowIcon={<KeyboardArrowLeft />}
      inputVariant="outlined"
      rightArrowIcon={<KeyboardArrowRight />}
    />
  )
}

export default DatePickerField
