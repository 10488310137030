import styled from 'styled-components'

const CardHeader = styled.div`
  padding: ${({ theme, lite }) => {
    if (lite) return `${theme.baseline * 3}px ${theme.baseline * 3}px 0`

    return `${theme.baseline * 3}px`
  }};
  border-bottom-color: ${({ theme, lite }) => {
    if (!lite) return `${theme.colours.steel}`
  }};
  border-bottom-width: ${({ lite }) => {
    if (!lite) return `1px`
  }};
  border-bottom-style: ${({ lite }) => {
    if (!lite) return `solid`
  }};
`

export default CardHeader
