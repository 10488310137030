import React, { useContext, useState, useEffect } from 'react'
import styled, { keyframes, ThemeContext } from 'styled-components'
import { useTransition, animated } from 'react-spring'
import {
  mdiClose,
  mdiInformationOutline,
  mdiAlertCircleOutline,
  mdiCheck,
} from '@mdi/js'
import Icon from '@mdi/react'

import Typography from './Typography'
import Spacing from './Spacing'
import FlexBox from './FlexBox'

const shrinkKeyframes = keyframes`
  from {
    height: 100%;
  }
  to {
    height: 0%;
  }
`

const ToastWrapper = styled(animated.div)`
  position: relative;
  background-color: ${({ theme, colour }) => theme.colours[colour]};
  border-radius: 4px;
  margin: 8px 8px 0 0;
  svg {
    margin: 24px 8px;
  }
`

const Countdown = styled.div`
  animation-duration: ${({ autoDismissTimeout }) => `${autoDismissTimeout}ms`};
  animation-name: ${shrinkKeyframes};
  animation-timing-function: linear;
  opacity: ${({ opacity }) => opacity};
  animation-play-state: ${({ isRunning }) =>
    isRunning ? 'running' : 'paused'};
  background-color: rgba(0, 0, 0, 0.08);
  bottom: 0;
  height: 0;
  left: 0;
  position: absolute;
  width: 100%;
`

const ToastContent = styled.div`
  background-color: ${({ theme }) => theme.colours.snow};
  padding: ${({ theme }) => `${theme.baseline * 3}px`};
  z-index: 1;
  width: 360px;
  border-color: ${({ theme }) => theme.colours.steel};
  border-width: 1px;
  border-style: solid;
  border-left: none;
  border-radius: 0px 4px 4px 0px;
  svg {
    cursor: pointer;
    margin: 0;
  }
`

export default function Toast({
  appearance,
  children,
  onDismiss,
  autoDismiss,
  autoDismissTimeout,
  isRunning,
  onMouseEnter,
  onMouseLeave,
  transitionState,
  transitionDuration,
}) {
  const themeContext = useContext(ThemeContext)

  const { slate } = themeContext.colours

  //calaculate which kind of toast to show

  let icon
  let title
  let colour

  switch (appearance) {
    case 'success':
      icon = (
        <Icon path={mdiCheck} title="User Profile" size="24px" color="white" />
      )
      title = 'Success'
      colour = 'lime'
      break

    case 'error':
      icon = (
        <Icon
          path={mdiAlertCircleOutline}
          title="User Profile"
          size="24px"
          color="white"
        />
      )
      title = 'Error'
      colour = 'ruby'
      break

    case 'info':
    default:
      icon = (
        <Icon
          path={mdiInformationOutline}
          title="User Profile"
          size="24px"
          color="white"
        />
      )
      title = 'Information'
      colour = 'mirror'
      break
  }

  const [showNotifications, setShowNotifications] = useState(true)

  useEffect(() => {
    if (transitionState === 'exiting') {
      setShowNotifications(false)
    }
  }, [transitionState])

  const transitions = useTransition(showNotifications, null, {
    config: { duration: transitionDuration },
    from: { right: -450, opacity: 0 },
    enter: { right: 1, opacity: 1 },
    leave: { right: -450, opacity: 0 },
  })

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <ToastWrapper
          colour={colour}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          key={key}
          style={props}
        >
          <Countdown
            opacity={autoDismiss ? 1 : 0}
            autoDismissTimeout={autoDismissTimeout}
            isRunning={isRunning}
          />
          <FlexBox alignItems="flex-start">
            {icon}
            <ToastContent>
              <FlexBox justifyContent="space-between">
                <Typography variant="bodyLarge">{title}</Typography>
                <Icon
                  path={mdiClose}
                  title="User Profile"
                  size="24px"
                  color={slate}
                  onClick={onDismiss}
                />
              </FlexBox>
              <Spacing multiplier={1} />
              <Typography variant="bodySmall">{children}</Typography>
            </ToastContent>
          </FlexBox>
        </ToastWrapper>
      )
  )
}
