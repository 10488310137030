import React, { useContext, useState } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { UserContext } from '../../../util/PageWrapper'

import { Container, Row, Col } from 'styled-bootstrap-grid'
import { IconButton, Tooltip } from '@material-ui/core'

import Loading from '../../../components/Loading'
import Button from '../../../components/Button'
import Title from '../../../components/Title'
import Breadcrumbs from '../../../components/Breadcrumbs'
import Table from '../../../components/Table'

import Icon from '@mdi/react'
import { mdiPlusCircle } from '@mdi/js'
import { RemoveRedEye } from '@material-ui/icons'

const GET_USERS = gql`
  query getUsers($pageIndex: Int, $pageSize: Int, $sortBy: [JSONObject]) {
    getUsers(pageIndex: $pageIndex, pageSize: $pageSize, sortBy: $sortBy) {
      users {
        id
        fullName
        email
        archived
        roleName
      }
      pageCount
    }
  }
`

export default function UserList() {
  const user = useContext(UserContext)

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [sortBy, setSortBy] = useState([])

  const { loading, error, data } = useQuery(GET_USERS, {
    variables: {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (loading) {
    return <Loading />
  }

  if (error || user.roleId < 5) {
    return null
  }

  let tableData = []

  if (data) {
    tableData = data.getUsers.users.map((user) => ({
      _id: user.fullName,
      roleId: user.roleName,
      email: user.email,

      actions: (
        <Tooltip title="View User Details" placement="top">
          <IconButton
            aria-label="View"
            component={Link}
            to={'user/edit/' + user.id}
          >
            <RemoveRedEye />
          </IconButton>
        </Tooltip>
      ),
    }))
  }

  return (
    <>
      <Breadcrumbs
        items={[
          { label: 'Admin Settings', to: '/admin-settings' },
          { label: 'Users' },
        ]}
      />
      <Title
        title="Users"
        lite
        button={
          <Button
            colour="brand"
            component={Link}
            to="users/add"
            icon={
              <Icon
                path={mdiPlusCircle}
                title="Add Secure Link"
                size="24px"
                color="white"
              />
            }
          >
            New User
          </Button>
        }
      />
      <Container>
        <Row>
          <Col md={12}>
            <Table
              headers={[
                {
                  Header: 'Name',
                  accessor: '_id',
                  disableFilters: true,
                },

                {
                  Header: 'Role',
                  accessor: 'roleId',
                  disableFilters: true,
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                  disableFilters: true,
                },

                {
                  accessor: 'actions',
                  disableSortBy: true,
                  disableFilters: true,
                },
              ]}
              data={tableData}
              loading={loading}
              updatePageIndex={setPageIndex}
              updatePageSize={setPageSize}
              pageSize={pageSize}
              pageIndex={pageIndex}
              sortBy={sortBy}
              updateSortBy={setSortBy}
              pageCount={data?.getSecureShareLinks?.pageCount}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}
