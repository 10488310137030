import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../../util/PageWrapper'
import { useToasts } from 'react-toast-notifications'
import { Form } from 'react-final-form'

import { Container, Row, Col } from 'styled-bootstrap-grid'

import Card from '../../../components/Card'
import CardBody from '../../../components/CardBody'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import Breadcrumbs from '../../../components/Breadcrumbs'
import Title from '../../../components/Title'
import Multiselect from '../../../components/Multiselect'

import Icon from '@mdi/react'
import { mdiContentSave } from '@mdi/js'

const REGISTER_WIFI_USER_BY_ADMIN = gql`
  mutation createUserByAdmin(
    $firstName: String!
    $lastName: String!
    $email: EmailAddress!
    $password: String!
    $organisations: [String!]!
  ) {
    createUserByAdmin(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      organisations: $organisations
    ) {
      id
      fullName
    }
  }
`

const GET_ORGANISATIONS = gql`
  query getOrganisations {
    getOrganisations {
      id
      name
    }
  }
`

export default function Register() {
  const user = useContext(UserContext)
  const { addToast } = useToasts()
  const history = useHistory()

  const { loading, data } = useQuery(GET_ORGANISATIONS, {
    fetchPolicy: 'cache-and-network',
  })

  const [createUserByAdmin, { loading: createUserByAdminLoading }] =
    useMutation(REGISTER_WIFI_USER_BY_ADMIN, {
      onCompleted: ({ createUserByAdmin }) => {
        addToast(
          `User ${createUserByAdmin.fullName} has been successfully created`,
          {
            appearance: 'success',
          }
        )
        history.push(`../user/edit/${createUserByAdmin.id}`)
      },
    })

  if (user.roleId < 5 || loading) {
    return null
  }

  return (
    <Form
      initialValues={{ organisations: [] }}
      onSubmit={({
        firstName,
        lastName,
        email,
        confirmPassword,
        organisations,
      }) => {
        const response = createUserByAdmin({
          variables: {
            firstName,
            lastName,
            email,
            password: confirmPassword,
            organisations,
          },
        }).catch((error) => {
          if (error.message === 'Email already in use') {
            return {
              email: 'Email already in use',
            }
          } else {
            addToast('Failed to create user, please try again', {
              appearance: 'error',
            })
          }
        })

        if (!response.data) return response
      }}
      validate={({ confirmPassword, password, organisations }) => {
        const errors = {}

        if (!organisations[0]) {
          errors.organisations = 'Required'
        }

        if (confirmPassword && confirmPassword !== password) {
          errors.confirmPassword = 'Passwords Must Match'
        }

        return errors
      }}
      render={({
        values,
        handleSubmit,
        pristine,
        invalid,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Breadcrumbs
              items={[
                { label: 'Admin Settings', to: '/admin-settings' },
                { label: 'Users', to: '/admin-settings/users' },
                { label: 'New User' },
              ]}
            />
            <Title title="New User" lite />
            <Container>
              <Card>
                <CardBody>
                  <Row>
                    <Col md="5">
                      <Input
                        name="firstName"
                        required
                        label="First Name"
                        type="text"
                      />
                    </Col>
                    <Col md="5">
                      <Input
                        name="lastName"
                        required
                        label="Last Name"
                        type="text"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="5">
                      <Input
                        name="password"
                        required
                        fullwidth
                        label="Password"
                        type="password"
                        autoComplete
                      />
                    </Col>
                    <Col md="5">
                      <Input
                        name="confirmPassword"
                        required
                        fullwidth
                        label="Confirm Password"
                        type="password"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="10">
                      <Input
                        name="email"
                        required
                        fullwidth
                        label="Email Address"
                        type="email"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="10">
                      <Multiselect
                        name="organisations"
                        label="Organisations"
                        required
                        options={data.getOrganisations.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <Button
                        disabled={
                          pristine || (invalid && !dirtySinceLastSubmit)
                        }
                        type="Submit"
                        loading={createUserByAdminLoading}
                        icon={
                          <Icon
                            path={mdiContentSave}
                            title="Copy Secure Link"
                            size="24px"
                          />
                        }
                      >
                        Create
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Container>
          </>
          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
        </form>
      )}
    />
  )
}
