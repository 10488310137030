import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import { useMutation, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useToasts } from 'react-toast-notifications'

import Typography from '../components/Typography'
import Spacing from '../components/Spacing'
import Input from '../components/Input'
import Button from '../components/Button'
import Loading from '../components/Loading'
import Error from '../components/Error'

//image
import Logo from './../assets/images/logo-full.svg'
import FlexBox from '../components/FlexBox'

const CHECK_PASSWORD_RESET_TOKEN = gql`
  query checkPasswordResetToken($resetToken: String!) {
    checkPasswordResetToken(resetToken: $resetToken) {
      id
    }
  }
`

const RESET_PASSWORD = gql`
  mutation resetPassword($userId: ID!, $password: String!) {
    resetPassword(userId: $userId, password: $password)
  }
`

export default function ResetPassword({
  match: {
    params: { resetToken },
  },
  history,
}) {
  const { addToast } = useToasts()
  const [passwordReset, setPasswordReset] = useState(false)

  const { loading, error, data } = useQuery(CHECK_PASSWORD_RESET_TOKEN, {
    variables: {
      resetToken: resetToken,
    },
  })

  const [resetPassword, { loading: resetPasswordLoading }] = useMutation(
    RESET_PASSWORD,
    {
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
      onCompleted: () => {
        setPasswordReset(true)
      },
    }
  )

  //redirect user to home page if they try to access this page whilst already logged in

  if (Cookies.get('dd')) history.push('/')

  if (loading) return <Loading />

  if (error) return <Error error={error} />

  const userId = data.checkPasswordResetToken.id

  return (
    <Fragment>
      <Container>
        <Row justifyContent="center">
          <Col col xl="6">
            <Spacing multiplier={4} />
            <img
              src={Logo}
              alt="Daffodil Dashboard Logo"
              height="56px"
              width="100%"
            />
          </Col>
        </Row>
      </Container>
      <Spacing multiplier={3} />
      {!passwordReset ? (
        <Fragment>
          <Typography variant="h5" center>
            Reset Password
          </Typography>
          <Spacing multiplier={6} />
          <Form
            onSubmit={({ confirmPassword }) => {
              resetPassword({
                variables: { userId: userId, password: confirmPassword },
              })
            }}
            validate={({ newPassword, confirmPassword }) => {
              let errors = {}

              if (confirmPassword && confirmPassword !== newPassword) {
                errors.confirmPassword = 'Passwords Must Match'
              }

              return errors
            }}
            render={({ handleSubmit, pristine, invalid }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Container>
                  <Row justifyContent="center">
                    <Col col xl="4">
                      <Input
                        name="newPassword"
                        required
                        fullwidth
                        label="New Password"
                        type="password"
                      />
                      <Input
                        name="confirmPassword"
                        required
                        fullwidth
                        label="Confirm Password"
                        type="password"
                      />
                    </Col>
                    <Spacing multiplier={1} />
                  </Row>
                  <Row justifyContent="center">
                    <Col col xl="4">
                      <Button
                        colour="brand"
                        fullwidth
                        loading={resetPasswordLoading}
                        disabled={pristine || invalid}
                        type="submit"
                      >
                        Reset Password
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </form>
            )}
          />
        </Fragment>
      ) : (
        <Container>
          <Row justifyContent="center">
            <Col col xl="6">
              <Typography variant="h5" center>
                Password Updated
              </Typography>
              <Spacing multiplier={6} />
              <Typography variant="bodySmall" colour="slate" center>
                Your password has been changed. Click the button below to return
                to the login page
              </Typography>
              <Spacing multiplier={5} />
              <FlexBox justifyContent="center">
                <Button colour="brand" component={Link} to="/login">
                  Back To Login
                </Button>
              </FlexBox>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  )
}
