import React, { Fragment, useContext } from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { UserContext } from '../../../util/PageWrapper'

import { Form } from 'react-final-form'
import { useToasts } from 'react-toast-notifications'
import validator from 'validator'

import { Container, Row, Col } from 'styled-bootstrap-grid'
import {
  CardContent,
  IconButton,
  CardHeader,
  CardActions,
} from '@material-ui/core'
import { Save, Clear } from '@material-ui/icons'

import UserEdit from './UserEdit'
import ResetPassword from './ResetPassword'
import Loading from '../../../components/Loading'
import Spacing from '../../../components/Spacing'
import Typography from '../../../components/Typography'
import FlexBox from '../../../components/FlexBox'
import Button from '../../../components/Button'
import CardBody from '../../../components/CardBody'
import Modal from '../../../components/Modal'
import Breadcrumbs from '../../../components/Breadcrumbs'
import Title from '../../../components/Title'
import Card from '../../../components/Card'

export const ARCHIVE_USER = gql`
  mutation ($userId: String!) {
    archiveUser(userId: $userId) {
      id
      fullName
    }
  }
`

export const UNARCHIVE_USER = gql`
  mutation ($userId: String!) {
    unArchiveUser(userId: $userId) {
      id
      fullName
    }
  }
`

const UPDATE_USER_PERSONAL_DETAILS = gql`
  mutation updateUserPersonalDetailsByAdmin(
    $userId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $roleId: String!
    $organisations: [String!]!
  ) {
    updateUserPersonalDetailsByAdmin(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      email: $email
      roleId: $roleId
      organisations: $organisations
    ) {
      id
      fullName
    }
  }
`

const GET_USER = gql`
  query getUser($userId: String!) {
    getUser(userId: $userId) {
      id
      firstName
      lastName
      fullName
      email
      roleId
      archived
      organisations {
        id
        name
      }
    }
  }
`

const GET_ORGANISATIONS = gql`
  query getOrganisations {
    getOrganisations {
      id
      name
    }
  }
`

export default function UserView() {
  const userFromContext = useContext(UserContext)
  const { addToast } = useToasts()

  const params = useParams()
  const { userId } = params

  const { loading, error, data } = useQuery(GET_USER, {
    variables: {
      userId: userId,
    },
  })

  const { loading: orgsLoading, data: orgsData } = useQuery(GET_ORGANISATIONS, {
    fetchPolicy: 'cache-and-network',
  })

  const [updateUser, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER_PERSONAL_DETAILS,
    {
      onCompleted: ({ updateUserPersonalDetailsByAdmin }) => {
        addToast(
          updateUserPersonalDetailsByAdmin.fullName +
            ' has been successfully updated',
          {
            appearance: 'success',
          }
        )
      },
      onError: () => {
        addToast('Failed to update user, please try again', {
          appearance: 'error',
        })
      },
      refetchQueries: [
        {
          query: GET_USER,
          variables: {
            userId: userId,
          },
        },
      ],
    }
  )
  const [unArchiveUser, { loading: unArchiveUserLoading }] = useMutation(
    UNARCHIVE_USER,
    {
      onCompleted: ({ unArchiveUser }) => {
        addToast(unArchiveUser.fullName + ' has been successfully updated', {
          appearance: 'success',
        })
        hideArchiveModal()
      },
      onError: () => {
        addToast('Failed to update user, please try again', {
          appearance: 'error',
        })
      },
      refetchQueries: [
        {
          query: GET_USER,
          variables: {
            userId: userId,
          },
        },
      ],
    }
  )
  const [archiveUser, { loading: archiveUserLoading }] = useMutation(
    ARCHIVE_USER,
    {
      onCompleted: ({ archiveUser }) => {
        addToast(archiveUser.fullName + ' has been successfully updated', {
          appearance: 'success',
        })
        hideArchiveModal()
      },
      onError: () => {
        addToast('Failed to update user, please try again', {
          appearance: 'error',
        })
      },
      refetchQueries: [
        {
          query: GET_USER,
          variables: {
            userId: userId,
          },
        },
      ],
    }
  )

  const [showArchiveModal, hideArchiveModal] = useModal(() => (
    <Modal>
      <Card>
        <CardHeader
          action={
            <IconButton aria-label="Close Pop-up" onClick={hideArchiveModal}>
              <Clear />
            </IconButton>
          }
          title="Are You Sure?"
        />
        <CardContent>
          <Typography variant="body1" gutterBottom>
            Archiving a user can be undone.
          </Typography>
        </CardContent>
        <CardActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            colour="show"
            fullwidth
            variant="outline"
            type="button"
            onClick={hideArchiveModal}
          >
            Cancel
          </Button>

          <Button
            colour="brand"
            fullwidth
            onClick={() => {
              archiveUser({
                variables: {
                  userId: userId,
                },
              })
            }}
          >
            Continue
          </Button>
        </CardActions>
      </Card>
    </Modal>
  ))

  if (error || userFromContext.roleId < 5) {
    return null
  }

  if (loading || orgsLoading) {
    return <Loading />
  }

  const { getUser } = data
  const user = getUser

  return (
    <>
      <Breadcrumbs
        items={[
          { label: 'Admin Settings', to: '/admin-settings' },
          { label: 'Users', to: '/admin-settings/users' },
          { label: 'Edit User' },
        ]}
      />
      <Title
        title={`Edit User - ${user.fullName}`}
        lite
        button={
          <FlexBox justifyContent="flex-end">
            {user.archived ? (
              <Button
                variant="contained"
                style={{
                  marginLeft: '24px',
                  backgroundColor: 'red',
                  color: 'white',
                }}
                onClick={() => {
                  unArchiveUser({
                    variables: {
                      userId: userId,
                    },
                  })
                }}
              >
                Unarchive User
              </Button>
            ) : (
              <Fragment>
                <ResetPassword email={user.email} />

                <Button
                  variant="contained"
                  style={{
                    marginLeft: '24px',
                    backgroundColor: 'red',
                    color: 'white',
                  }}
                  onClick={showArchiveModal}
                >
                  Archive User
                </Button>
              </Fragment>
            )}
          </FlexBox>
        }
      />

      <Container>
        <Spacing multiplier={3} />

        <Row>
          <Col>
            <Card>
              <CardBody>
                <Form
                  initialValues={{
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    roleId: user.roleId.toString(),
                    organisations: user.organisations.map((org) => org.id),
                  }}
                  onSubmit={(values) => {
                    updateUser({
                      variables: {
                        userId: userId,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        roleId: values.roleId,
                        organisations: values.organisations,
                      },
                    })
                  }}
                  validate={(values) => {
                    const errors = {}

                    const requiredFields = [
                      'firstName',
                      'lastName',
                      'email',
                      'organisations',
                    ]

                    requiredFields.forEach((requiredField) => {
                      if (!values[requiredField] || !values[requiredField][0]) {
                        errors[requiredField] = 'Required'
                      }
                    })

                    if (
                      values.email &&
                      !validator.isEmail(values.email) &&
                      !values.email.includes('.con') &&
                      !values.email.includes('.coma')
                    ) {
                      errors.email = 'Invalid Email'
                    }

                    return errors
                  }}
                  render={({
                    handleSubmit,
                    pristine,
                    invalid,
                    submitting,
                    values,
                  }) => (
                    <form onSubmit={handleSubmit} noValidate>
                      <UserEdit
                        orgs={orgsData.getOrganisations.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))}
                      />

                      {!user.archived && (
                        <Button
                          disabled={pristine || invalid || submitting}
                          loading={
                            updateUserLoading ||
                            unArchiveUserLoading ||
                            archiveUserLoading
                          }
                          type="submit"
                          icon={<Save style={{ marginRight: '10px' }} />}
                        >
                          Save
                        </Button>
                      )}
                      {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                    </form>
                  )}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
