import React, { Fragment } from 'react'
import styled from 'styled-components'
import Typography from './Typography'
import FlexBox from './FlexBox'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'
import Spacing from './Spacing'

const BreadcrumbDivider = styled.div`
  margin: ${({ theme }) => {
    return `0 ${theme.baseline}px`
  }};
`

export default function Breadcrumbs({ items }) {
  return (
    <Container>
      <Row justifyContent="center">
        <Col col xl="12">
          <FlexBox>
            {items.map((item, index) => (
              <Fragment key={index}>
                {index !== 0 && (
                  <BreadcrumbDivider>
                    <Typography variant="label">/</Typography>
                  </BreadcrumbDivider>
                )}
                {item.to ? (
                  <Typography
                    variant="label"
                    colour="slate"
                    component={Link}
                    to={item.to}
                  >
                    {item.label}
                  </Typography>
                ) : (
                  <Typography variant="label" colour="slate">
                    {item.label}
                  </Typography>
                )}
              </Fragment>
            ))}
          </FlexBox>
          <Spacing multiplier={2} />
        </Col>
      </Row>
    </Container>
  )
}
