import React, { useState, useContext, useEffect } from 'react'
import { useCombobox } from 'downshift'
import { useField } from 'react-final-form'
import Icon from '@mdi/react'
import { mdiMagnify } from '@mdi/js'
import styled, { ThemeContext } from 'styled-components'
import { matchSorter } from 'match-sorter'
import validator from 'validator'

import Typography from './Typography'
import FlexBox from './FlexBox'
import Dropdown from './Dropdown'
import MenuItem from './MenuItem'
import Spacing from './Spacing'
import { StyledInput } from './Input'
import Divider from './Divider'

const DropdownLookupInputWrapper = styled.div`
  cursor: pointer;
  input {
    margin-bottom: 0;
    cursor: text;
  }
`

export default function DropdownLookup({
  options,
  required,
  name,
  label,
  type,
  helperText,
  fullwidth,
  disableSelect,
  placeholder,
  autoComplete,
  additionalMenuItem,
  allowCustomInput,
  serverFilterFunction,
  ...rest
}) {
  const {
    colours: { mirror },
  } = useContext(ThemeContext)

  const [showTypeMessage, setShowTypeMessage] = useState(!!serverFilterFunction)

  const {
    input: { onChange, value: fieldValue, ...inputRest },
    meta,
  } = useField(name, {
    validate: (value) => {
      //required validator
      if (required && (!value || !value.trim())) {
        return 'Required'
      }

      //validate based on type
      switch (type) {
        //email validation
        case 'email':
          if (
            value &&
            (!validator.isEmail(value) ||
              value.includes('.con') ||
              value.includes('.coma'))
          ) {
            return 'Invalid Email'
          }

          break

        default:
          break
      }
    },
  })

  //get intialValues and filters if there is one

  const initialValue = options?.find(
    ({ value }) => value.toLowerCase() === fieldValue.toLowerCase()
  )

  const [inputItems, setInputItems] = useState(options || [])

  //set default filter
  useEffect(() => {
    if (fieldValue && initialValue && options) {
      setInputItems(
        matchSorter(options, initialValue.label, {
          keys: ['label'],
        })
      )
    }

    if (!fieldValue) {
      setInputItems(options || [])
    }
  }, [fieldValue, initialValue, options])

  // if we're using serverside filtering, we need to update the item list when the component updates because it cant be updated on input change due to lag
  useEffect(() => {
    if (serverFilterFunction && options) setInputItems(options)
  }, [options, serverFilterFunction])

  const {
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    itemToString: ({ label }) => (label ? String(label) : ''),
    initialSelectedItem: initialValue,
    onInputValueChange: ({ inputValue }) => {
      if (serverFilterFunction) {
        serverFilterFunction(inputValue)

        setShowTypeMessage(inputValue.length < 3)

        if (allowCustomInput) {
          onChange(inputValue)
        } else {
          //the eneterd value must be part of the list, check the current list to see if it's in there and update if it is
          const option = options?.find(
            ({ label }) => label.toLowerCase() === inputValue.toLowerCase()
          )

          onChange(option?.value)
        }
      } else {
        setInputItems(matchSorter(options, inputValue, { keys: ['label'] }))
        // get actual value to store in form

        const option = options.find(
          ({ label }) => label.toLowerCase() === inputValue.toLowerCase()
        )

        const value = option?.value || allowCustomInput ? inputValue : null

        onChange(value)
      }
    },
  })

  let errorMessage

  if (meta.error && meta.touched) {
    errorMessage = meta.error
  } else if (!!meta.submitError && !meta.dirtySinceLastSubmit) {
    errorMessage = meta.submitError
  }

  return (
    <StyledInput {...rest} fullwidth={fullwidth ? 1 : 0} error={!!errorMessage}>
      <FlexBox justifyContent="space-between">
        <FlexBox>
          <Typography variant="label" {...getLabelProps()}>
            {label}
          </Typography>
          {!required && label && (
            <Typography variant="label" className="optional-text">
              (Optional)
            </Typography>
          )}
        </FlexBox>
      </FlexBox>
      <Dropdown fullwidth>
        <DropdownLookupInputWrapper
          className="input-container"
          {...getComboboxProps()}
          dropdownTrigger
        >
          <input
            {...getInputProps({
              ...rest,
              ...inputRest,
              ...getToggleButtonProps(),
            })}
            autoComplete={autoComplete ? 'on' : 'off'} // Needed to disable autocomplete
          />

          <Icon path={mdiMagnify} title="Search" size="24px" color={mirror} />
        </DropdownLookupInputWrapper>
        <div {...getMenuProps()}>
          {inputItems.length ? (
            <>
              {inputItems.map((item, index) => {
                const { onClick, ...itemProps } = getItemProps({
                  item,
                  index,
                })

                return (
                  <div {...itemProps} key={item.value}>
                    <MenuItem onClick={onClick}>
                      {item.component ? (
                        item.component
                      ) : (
                        <Typography variant="bodySmall">
                          {item.label}
                        </Typography>
                      )}
                    </MenuItem>
                  </div>
                )
              })}
            </>
          ) : (
            <MenuItem>
              {showTypeMessage ? (
                <Typography variant="caption">
                  Type to begin searching
                </Typography>
              ) : (
                <Typography variant="bodySmall">No Results Found</Typography>
              )}
            </MenuItem>
          )}

          {additionalMenuItem && (
            <>
              <Divider />
              <Spacing multiplier={1} />
              <MenuItem>{additionalMenuItem}</MenuItem>
            </>
          )}
        </div>
      </Dropdown>
      <Spacing multiplier={3} />
      {!!errorMessage ? (
        <Typography variant="caption" colour="ruby" className="helper-text">
          {errorMessage}
        </Typography>
      ) : !!helperText ? (
        <Typography variant="caption" colour="slate" className="helper-text">
          {helperText}
        </Typography>
      ) : null}
    </StyledInput>
  )
}
