import React from 'react'
import FlexBox from './FlexBox'
import Spacing from './Spacing'
import Typography from './Typography'

import ErrorImage from './../assets/images/error.svg'

export default function Error({ error }) {
  let errorMessage = 'This error has been logged. Please try again.'

  if (error?.graphQLErrors[0]?.extensions.code === 'BAD_USER_INPUT')
    errorMessage = error.graphQLErrors[0].message

  return (
    <FlexBox flexDirection="column">
      <Spacing multiplier={6} />
      <img src={ErrorImage} alt="Error" height="213px" width="100%" />
      <Spacing multiplier={8} />
      <Typography variant="h5" center>
        Something's not right here.
      </Typography>
      <Spacing multiplier={3} />
      <Typography variant="bodySmall" colour="slate" center>
        {errorMessage}
      </Typography>
      <Spacing multiplier={6} />
    </FlexBox>
  )
}
