import React, { Fragment } from 'react'
import styled, { keyframes } from 'styled-components'
import { darken, rgba } from 'polished'
import Typography from './Typography'

const StyledButton = styled.button`
  background-color: ${({
    theme,
    colour = 'brand',
    disabled,
    loading,
    variant = 'contained',
  }) => {
    if (loading) return darken(0.08, theme.colours[colour])

    if (disabled && variant !== 'outline')
      return rgba(theme.colours[colour], 0.4)

    if (disabled && variant === 'outline')
      return rgba(theme.colours.pitch, 0.12)

    if (variant === 'outline') return rgba(theme.colours.pitch, 0)

    return theme.colours[colour]
  }};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 150ms;
  position: relative;
  width: ${({ fullwidth }) => {
    if (fullwidth) return '100%'
  }};
  padding: 8px 18px;
  cursor: ${({ disabled, loading }) => {
    if (disabled || loading) return 'not-allowed'

    return 'pointer'
  }};
  border: ${({ variant, theme }) => {
    if (variant === 'outline') return `solid 1px ${theme.colours.mirror}`

    return 'none'
  }};
  border-radius: 4px;
  &:focus,
  &:active {
    outline: none;
    background-color: ${({
      theme,
      colour = 'brand',
      disabled,
      loading,
      variant = 'contained',
    }) => {
      if (!disabled && !loading && variant === 'outline')
        return rgba(theme.colours.pitch, 0.12)

      if (!disabled && !loading) return darken(0.16, theme.colours[colour])
    }};
  }
  &:hover {
    background-color: ${({
      theme,
      colour = 'brand',
      disabled,
      loading,
      variant = 'contained',
    }) => {
      if (!disabled && !loading && variant === 'outline')
        return rgba(theme.colours.pitch, 0.04)

      if (!disabled && !loading) return darken(0.08, theme.colours[colour])
    }};
  }
  svg {
    margin-right: 8px;
  }
`

const HiddenButtonContent = styled.div`
  opacity: 0;
  display: flex;
`

const stretch = keyframes`
  0% {
      transform: scale(.5);
      background-color: #ffffff;
  }
  50% {
      background-color: #ffffff;
  }
  100% {
      transform: scale(1);
      background-color: #ffffff;
  }
`

const ButtonLoadingWrapper = styled.div`
  position: absolute;
  width: 40px;
  display: flex;
  align-items: center;
  span {
    height: 8px;
    width: 8px;
    background-color: white;
    display: block;
    border-radius: 100%;
    animation-delay: 0.1s;
    margin-right: 5px;
    animation-direction: alternate;
    animation-duration: 0.5s;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-name: ${stretch};
    animation-play-state: running;
    animation-timing-function: ease-out;

    &:first-child {
      animation-delay: 0s;
    }

    &:last-child {
      animation-delay: 0.2s;
      margin-right: 0;
    }
  }
`

export default function Button({
  children,
  component,
  icon,
  loading,
  ...rest
}) {
  //calculate button text colour
  const { variant, colour } = rest

  let buttonTextColour = 'snow'

  if (variant === 'outline') buttonTextColour = colour

  return (
    <StyledButton as={component} loading={loading ? 1 : 0} {...rest}>
      {loading ? (
        <Fragment>
          <HiddenButtonContent>
            {icon && icon}
            <Typography variant="button" colour={buttonTextColour}>
              {children}
            </Typography>
          </HiddenButtonContent>
          <ButtonLoadingWrapper>
            <span />
            <span />
            <span />
          </ButtonLoadingWrapper>
        </Fragment>
      ) : (
        <Fragment>
          {icon && icon}
          <Typography variant="button" colour={buttonTextColour}>
            {children}
          </Typography>
        </Fragment>
      )}
    </StyledButton>
  )
}
