import styled from 'styled-components'

const FlexBox = styled.div`
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : '')};
`

export default FlexBox
