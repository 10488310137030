import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

const GlobalStyle = createGlobalStyle`
  ${reset}
  body {
    background-color: ${({ theme }) => theme.colours.chalk};
  }
  /* By default, all images should be sized to fit their container */
  img {
    object-fit: contain;
    object-position: 50% 50%;
  }
  a:active {
    color: inherit;
  }
  html {
    overflow-y: scroll;
    &.lock, &.lock-side-modal {
      overflow-y: hidden;
    }
  }
`

export default GlobalStyle
