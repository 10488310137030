import React, { useContext } from 'react'
import { Field } from 'react-final-form'
import styled, { ThemeContext } from 'styled-components'
import { rgba } from 'polished'
import Icon from '@mdi/react'
import { mdiMenuDown } from '@mdi/js'

import Typography from './Typography'
import FlexBox from './FlexBox'
import Dropdown from './Dropdown'
import MenuItem from './MenuItem'

const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ fullwidth }) => {
    if (!fullwidth) return '320px'
  }};
  .optional-text {
    margin-left: 4px;
  }
  .helper-text {
    margin-top: -24px;
  }
  .select-components-wrapper {
    margin-bottom: 24px;
  }
`

const StyledSelect = styled.button`
  min-width: 160px;
  width: ${({ small }) => {
    if (!small) return '100%'
  }};
  max-width: ${({ fullwidth }) => {
    if (!fullwidth) return '320px'
  }};
  cursor: ${({ theme, readOnly, disabled }) => {
    if (readOnly || disabled) return 'not-allowed'

    return 'pointer'
  }};
  height: 40px;
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all ease-in-out 150ms;
  border-color: ${({ theme, error }) => {
    if (error) return theme.colours.ruby

    return theme.colours.steel
  }};
  border-radius: 4px;
  background-color: ${({ theme, readOnly, disabled }) => {
    if (readOnly || disabled) return rgba(theme.colours.pitch, 0.04)

    return theme.colours.snow
  }};
  color: ${({ theme, readOnly, disabled }) => {
    if (readOnly || disabled) return theme.colours.slate

    return theme.colours.pitch
  }};
  padding: 7px 8px 7px 16px;
  &:focus,
  &:active {
    border-color: ${({ theme, readOnly, disabled }) => {
      if (!readOnly && !disabled) return theme.colours.brand
    }};
    outline: none;
  }
`

export default function Select({
  options,
  name,
  required,
  label,
  helperText,
  placeholder,
  small,
  ...rest
}) {
  const theme = useContext(ThemeContext)
  //validation function, returns error message if theres a problem

  const validate = (value) => {
    //required validator
    if (required && (!value || !value.trim())) {
      return 'Required'
    }
  }

  if (rest.readOnly)
    return (
      <StyledSelectWrapper {...rest}>
        <div className="select-components-wrapper">
          <FlexBox>
            <Typography variant="label">{label}</Typography>
            {!required && (
              <Typography variant="label" className="optional-text">
                (Optional)
              </Typography>
            )}
          </FlexBox>

          <StyledSelect {...rest} dropdownTrigger type="button">
            <Typography variant="bodySmall" colour="slate">
              {rest.value}
            </Typography>
            <Icon
              path={mdiMenuDown}
              title="Add Secure Link"
              size="24px"
              color={theme.colours.brand}
            />
          </StyledSelect>
        </div>
        {!!helperText ? (
          <Typography variant="caption" colour="slate" className="helper-text">
            {helperText}
          </Typography>
        ) : null}
      </StyledSelectWrapper>
    )

  return (
    <Field
      name={name}
      validate={validate}
      format={(value) => {
        const selectedOption = options.find((option) => option.value === value)
        return selectedOption?.label
      }}
    >
      {({ input: { onChange, value, ...inputRest }, meta }) => {
        //get error

        let errorMessage

        if (meta.error && meta.touched) {
          errorMessage = meta.error
        } else if (!!meta.submitError && !meta.dirtySinceLastSubmit) {
          errorMessage = meta.submitError
        }

        return (
          <StyledSelectWrapper {...rest} error={!!errorMessage} {...inputRest}>
            <div className="select-components-wrapper">
              <FlexBox>
                <Typography variant="label">{label}</Typography>
                {!required && (
                  <Typography variant="label" className="optional-text">
                    (Optional)
                  </Typography>
                )}
              </FlexBox>
              <Dropdown fullwidth={!small}>
                <StyledSelect
                  {...rest}
                  error={!!errorMessage}
                  dropdownTrigger
                  type="button"
                  small={small}
                >
                  <Typography variant="bodySmall" colour="slate">
                    {value ? value : !!placeholder ? placeholder : 'Select'}
                  </Typography>
                  <Icon
                    path={mdiMenuDown}
                    title="Dropdown"
                    size="24px"
                    color={theme.colours.brand}
                  />
                </StyledSelect>
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => {
                      onChange(option.value)
                    }}
                  >
                    <Typography variant="bodySmall">{option.label}</Typography>
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
            {!!errorMessage ? (
              <Typography
                variant="caption"
                colour="ruby"
                className="helper-text"
              >
                {errorMessage}
              </Typography>
            ) : !!helperText ? (
              <Typography
                variant="caption"
                colour="slate"
                className="helper-text"
              >
                {helperText}
              </Typography>
            ) : null}
          </StyledSelectWrapper>
        )
      }}
    </Field>
  )
}
