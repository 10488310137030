const Theme = {
  colours: {
    brand: '#3F3CC6',
    mast: '#2D248A',
    pitch: '#1C1C26',
    slate: '#626C79',
    mirror: '#C3CAD8',
    steel: '#DFE3EB',
    chalk: '#F3F8FC',
    snow: '#FFFFFF',
    lime: '#66BC0C',
    amber: '#F6992B',
    ruby: '#E02020',
    azure: '#1344B4',
  },
  typography: {
    primary: 'Poppins, Helvetica, Arial, Sans-Serif',
    secondary: 'Roboto, Helvetica, Arial, Sans-Serif',
  },
  baseline: 8,
  // grid
  container: {
    padding: 40, // default 15
    maxWidth: {
      xl: 1220, // default 1140
      lg: 980, // default 960
      md: 740, //defaul 720
      sm: 560, //default 540
      xs: 560, //default 540
    },
  },
}

export default Theme
