import React, { useState, useEffect } from 'react'
import { Lottie } from '@crello/react-lottie'
import styled from 'styled-components'

import animationData from './../assets/animations/mono-loader.json'

const LoaderWrapper = styled.div`
  min-height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function Loading() {
  const [display, setDisplay] = useState(false)

  useEffect(() => {
    let timer = setTimeout(() => setDisplay(true), 250)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  if (!display) return null

  return (
    <LoaderWrapper>
      <Lottie
        height="160px"
        width="160px"
        config={{ animationData: animationData, loop: true }}
      />
    </LoaderWrapper>
  )
}
