import React, { Fragment, useState, useEffect } from 'react'
import { Form } from 'react-final-form'
import { useQuery, useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { isAfter, format } from 'date-fns'

import Typography from './../../components/Typography'
import Spacing from '../../components/Spacing'
import Input from './../../components/Input'
import Button from '../../components/Button'
import Card from '../../components/Card'
import CardBody from '../../components/CardBody'

//image
import ExpiredLinkImage from './../../assets/images/expired-link.svg'
import DeletedLinkImage from './../../assets/images/deleted-link.svg'
import Loading from '../../components/Loading'

const GET_SECURE_SHARE_DETAILS = gql`
  query getSecureShareDetails($secureShareReference: Int) {
    getSecureShareDetails(secureShareReference: $secureShareReference) {
      id
      passphrase
      expiryDate
      purpose
      content
      createdBy {
        id
        fullName
      }
      organisation {
        id
        name
        secureShareRetentionPeriod
      }
    }
  }
`

const CREATE_LINK_ACTIVITY = gql`
  mutation createLinkActivity($secureShareReference: Int!, $activity: String!) {
    createLinkActivity(
      secureShareReference: $secureShareReference
      activity: $activity
    )
  }
`

export default function ViewSecureLink({
  match: {
    params: { secureShareReference },
  },
}) {
  //states

  const [passphraseEntered, setPassphraseEntered] = useState(false)

  const { loading, error, data } = useQuery(GET_SECURE_SHARE_DETAILS, {
    variables: {
      secureShareReference: parseInt(secureShareReference),
    },
  })

  const [createLinkActivity] = useMutation(CREATE_LINK_ACTIVITY)

  // create link viewed activity
  useEffect(() => {
    createLinkActivity({
      variables: {
        secureShareReference: parseInt(secureShareReference),
        activity: 'Secure Share link viewed.',
      },
    })
  }, [createLinkActivity, secureShareReference])

  if (loading) return <Loading />

  if (error)
    return (
      <Container>
        <Row justifyContent="center">
          <Col col xl="5">
            <Spacing multiplier={8} />
            <Typography variant="h2" center>
              Daffodil Secure Share
            </Typography>
            <Spacing multiplier={12} />
            <img
              src={DeletedLinkImage}
              alt="Deleted"
              height="213px"
              width="100%"
            />
            <Spacing multiplier={10} />
            <Typography variant="h5" center>
              This link has been deleted
            </Typography>
            <Spacing multiplier={3} />
          </Col>
        </Row>
        <Row justifyContent="center">
          <Col col xl="6">
            <Typography variant="bodySmall" colour="slate" center>
              Please contact the sender if you believe this link has been
              deleted in error.
            </Typography>
          </Col>
        </Row>
      </Container>
    )

  // get data into consts

  const { expiryDate, passphrase, organisation, createdBy, purpose, content } =
    data.getSecureShareDetails

  //check if link is expired
  const isExpired = isAfter(new Date(), new Date(expiryDate))

  //check if link has a passphrase

  const hasPassphrase = !!passphrase

  //calculate retrentipn period

  let retentionPeriod

  switch (organisation.secureShareRetentionPeriod) {
    case '30days':
    default:
      retentionPeriod = '30 Days'
      break
  }

  let view

  if (isExpired) {
    view = (
      <Fragment>
        <Row justifyContent="center">
          <Col col xl="4">
            <Spacing multiplier={6} />
            <img
              src={ExpiredLinkImage}
              alt="Expired"
              height="213px"
              width="100%"
            />
            <Spacing multiplier={8} />
          </Col>
        </Row>
        <Row justifyContent="center">
          <Col col xl="6">
            <Typography variant="h5" center>
              {`This link expired on ${format(
                new Date(expiryDate),
                "dd/MM/yy 'at' HH:mm:ss"
              )}`}
            </Typography>
            <Spacing multiplier={3} />
          </Col>
        </Row>
        <Row justifyContent="center">
          <Col col xl="6">
            <Typography variant="bodySmall" colour="slate" center>
              {`Information is stored for an additional ${retentionPeriod} after link expiration before being deleted permanently.`}
            </Typography>
          </Col>
        </Row>
      </Fragment>
    )
  } else if (hasPassphrase && !passphraseEntered) {
    view = (
      <Row justifyContent="center">
        <Col col xl="6">
          <Form
            onSubmit={async ({ enteredPassphrase }) => {
              //check if passphrases match
              if (enteredPassphrase === passphrase) {
                createLinkActivity({
                  variables: {
                    secureShareReference: parseInt(secureShareReference),
                    activity: 'Secure Share link passphrase entered correctly.',
                  },
                })
                setPassphraseEntered(true)
              } else {
                createLinkActivity({
                  variables: {
                    secureShareReference: parseInt(secureShareReference),
                    activity:
                      'Secure Share link passphrase entered incorrectly.',
                  },
                })
              }

              //password entered wrong, display error in form
              return {
                enteredPassphrase: 'Incorrect Passphrase',
              }
            }}
            render={({
              handleSubmit,
              pristine,
              invalid,
              dirtySinceLastSubmit,
            }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Card>
                  <CardBody>
                    <Input
                      name="enteredPassphrase"
                      label="Passphrase"
                      type="password"
                      fullwidth
                      required
                    />
                    <Button
                      colour="brand"
                      disabled={pristine || (invalid && !dirtySinceLastSubmit)}
                      type="submit"
                      fullwidth
                    >
                      View Information
                    </Button>
                  </CardBody>
                </Card>
              </form>
            )}
          />
        </Col>
      </Row>
    )
  } else {
    view = (
      <Card>
        <CardBody>
          <Typography variant="h5">{`The below information has been shared with you by ${createdBy.fullName} of ${organisation.name}`}</Typography>
          <Spacing multiplier={2} />
          <Input
            name="purpose"
            required
            label="Purpose (what this information is for)"
            type="text"
            readOnly
            value={purpose}
          />
          <Input
            name="content"
            required
            fullwidth
            label="Password/Text"
            type="textarea"
            rows={6}
            readOnly
            value={content}
          />
          <Typography variant="bodySmall" colour="slate">
            {`This link will expire at ${format(
              new Date(expiryDate),
              "H:mm O 'on' d LLL y"
            )}`}
          </Typography>
        </CardBody>
      </Card>
    )
  }

  return (
    <Container>
      <Row justifyContent="center">
        <Col col xl="6">
          <Spacing multiplier={5} />
          <Typography variant="h2" center>
            Daffodil Secure Share
          </Typography>
          <Spacing multiplier={6} />
        </Col>
      </Row>
      {view}
    </Container>
  )
}
