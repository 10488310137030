import React from 'react'

import Spacing from './../components/Spacing'
import Typography from './../components/Typography'
import Button from './../components/Button'
import FlexBox from './../components/FlexBox'

import PageNotFoundImage from './../assets/images/404-img.svg'

export default function PageNotFound({ history }) {
  return (
    <FlexBox flexDirection="column">
      <Spacing multiplier={15} />
      <img
        src={PageNotFoundImage}
        alt="Page Not Found"
        height="309px"
        width="100%"
      />
      <Spacing multiplier={8} />
      <Typography variant="h5" center>
        It looks like this page is lost...
      </Typography>
      <Spacing multiplier={3} />
      <Typography variant="bodySmall" colour="slate" center>
        Don't worry, we have a search party out looking for it.
      </Typography>
      <Spacing multiplier={4} />
      <Button
        colour="brand"
        onClick={() => {
          history.goBack()
        }}
      >
        Go Back
      </Button>
    </FlexBox>
  )
}
