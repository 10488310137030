import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useToasts } from 'react-toast-notifications'

import Typography from '../components/Typography'
import Spacing from '../components/Spacing'
import Input from '../components/Input'
import Button from '../components/Button'

//image
import Logo from './../assets/images/logo-full.svg'
import FlexBox from '../components/FlexBox'

const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: EmailAddress!) {
    requestPasswordReset(email: $email)
  }
`

export default function ForgottenPassword({ history }) {
  const { addToast } = useToasts()
  const [resetSent, setResetSent] = useState(false)

  const [requestPasswordReset, { loading }] = useMutation(
    REQUEST_PASSWORD_RESET,
    {
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
      onCompleted: () => {
        setResetSent(true)
      },
    }
  )

  //redirect user to home page if they try to access this page whilst already logged in

  if (Cookies.get('dd')) history.push('/')

  return (
    <Fragment>
      <Container>
        <Row justifyContent="center">
          <Col col xl="6">
            <Spacing multiplier={4} />
            <img
              src={Logo}
              alt="Daffodil Dashboard Logo"
              height="56px"
              width="100%"
            />
          </Col>
        </Row>
      </Container>
      <Spacing multiplier={3} />
      {!resetSent ? (
        <Fragment>
          <Typography variant="h5" center>
            Password Recovery
          </Typography>
          <Spacing multiplier={6} />
          <Form
            onSubmit={({ email }) => {
              requestPasswordReset({
                variables: {
                  email: email,
                },
              })
            }}
            render={({ handleSubmit, pristine, invalid }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Container>
                  <Row justifyContent="center">
                    <Col col xl="4">
                      <Input
                        name="email"
                        required
                        fullwidth
                        label="Email Address"
                        type="email"
                      />
                      <Spacing multiplier={1} />
                    </Col>
                  </Row>
                  <Row justifyContent="center">
                    <Col col xl="4">
                      <Button
                        colour="brand"
                        fullwidth
                        loading={loading}
                        disabled={pristine || invalid}
                        type="submit"
                      >
                        Reset Password
                      </Button>
                      <Spacing multiplier={3} />
                      <Typography
                        variant="label"
                        component={Link}
                        to="login"
                        center
                      >
                        Back
                      </Typography>
                    </Col>
                  </Row>
                </Container>
              </form>
            )}
          />
        </Fragment>
      ) : (
        <Container>
          <Row justifyContent="center">
            <Col col xl="6">
              <Typography variant="h5" center>
                Thank You!
              </Typography>
              <Spacing multiplier={6} />
              <Typography variant="bodySmall" colour="slate" center>
                If you have an account, we'll email you a reset link.
              </Typography>
              <Spacing multiplier={2} />
              <Typography variant="bodySmall" colour="slate" center>
                If no email is received within five minutes, check the email
                address you submitted is correct and try again.
              </Typography>
              <Spacing multiplier={5} />
              <FlexBox justifyContent="center">
                <Button colour="brand" component={Link} to="login">
                  Back To Login
                </Button>
              </FlexBox>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  )
}
