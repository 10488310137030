import React, { useContext, useMemo, isValidElement, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { rgba } from 'polished'
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from 'react-table'
import {
  mdiMenuDown,
  mdiPageFirst,
  mdiChevronLeft,
  mdiViewColumn,
} from '@mdi/js'
import Icon from '@mdi/react'
import { matchSorter } from 'match-sorter'

import Card from './Card'
import CardBody from './CardBody'
import Typography from './Typography'
import CardHeader from './CardHeader'
import CardFooter from './CardFooter'
import FlexBox from './FlexBox'
import Spacing from './Spacing'
import Dropdown from './Dropdown'
import MenuItem from './MenuItem'
import Loading from './Loading'
import { Pagination, PaginationButton } from './Pagination'

import EmptyTable from './../assets/images/empty-table.svg'
import { StyledCheckbox } from './Checkbox'
import IconButton from './IconButton'

const StyledTableHeadCell = styled.th`
  text-align: left;
  padding: 0 0 20px;
  label {
    cursor: ${({ onClick }) => {
      if (onClick) return 'pointer'
    }};
    display: inline-flex;
    padding-right: 32px;
    position: relative;

    svg {
      position: absolute;
      right: 0;
    }
  }
`

const StyledTableHeader = styled.thead`
  border-bottom: ${({ theme }) =>
    `solid 1px ${rgba(theme.colours.mirror, 0.4)}`};
  width: 100%;
`

const StyledTable = styled.table`
  width: 100%;
`

const StyledTableRow = styled.tr`
  text-align: left;
  border-bottom: ${({ theme }) =>
    `solid 1px ${rgba(theme.colours.mirror, 0.4)}`};
`

const StyledTableDataCell = styled.td`
  padding: 20px 0;
  vertical-align: middle;
`

const StyledFilter = styled.div`
  .select-toggle {
    cursor: pointer;
    p {
      font-size: 12px;
      cursor: pointer;
    }
  }

  .dropdown-menu {
    min-width: 0;
  }

  input {
    min-width: 0;
    width: 100%;
    font-size: 12px;
    border: none;
    outline: none;
    font-family: 'Roboto';
    line-height: 26px;
  }
`

const StyledRowsPerPage = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  .rows-text {
    white-space: nowrap;
  }
  .page-select {
    border: ${({ theme }) => `solid 1px ${rgba(theme.colours.mirror, 0.4)}`};
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colours.snow};
    padding: 8px 8px 8px 16px;
    display: flex;
    outline: none;
    cursor: pointer;
  }
`

const StyledTableActions = styled.div`
  margin-left: auto;
  .checkbox-container {
    margin: 10px;
    white-space: nowrap;
  }
`

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search`}
    />
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val

//sorting exports

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const themeContext = useContext(ThemeContext)

  const { slate } = themeContext.colours

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <Dropdown>
      <FlexBox dropdownTrigger className="select-toggle">
        <Typography variant="bodySmall">{filterValue || 'All'}</Typography>
        <Icon path={mdiMenuDown} title="Dropdown" size="24px" color={slate} />
      </FlexBox>
      <MenuItem
        onClick={() => {
          setFilter(undefined)
        }}
      >
        <Typography variant="bodySmall">All</Typography>
      </MenuItem>
      {options.map((option, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            setFilter(option)
          }}
        >
          <Typography variant="bodySmall">{option}</Typography>
        </MenuItem>
      ))}
    </Dropdown>
  )
}

function truncatePagination(c, m) {
  var current = c,
    last = m,
    delta = 2,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i)
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1)
      } else if (i - l !== 1) {
        rangeWithDots.push('...')
      }
    }
    rangeWithDots.push(i)
    l = i
  }

  return rangeWithDots
}

export default function Table({
  title,
  headers,
  data,
  loading,
  updatePageIndex,
  updatePageSize,
  pageSize: currentPageSize,
  pageIndex: currentPageIndex,
  pageCount: controlledPageCount,
  sortBy: currentSortBy,
  updateSortBy,
}) {
  const themeContext = useContext(ThemeContext)

  const { mirror, pitch, brand } = themeContext.colours

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns: useMemo(() => headers, [headers]),
      data: useMemo(() => data, [data]),
      initialState: {
        pageIndex: currentPageIndex,
        pageSize: currentPageSize,
        sortBy: currentSortBy,
      },
      defaultColumn: {
        Filter: DefaultColumnFilter,
      },
      filterTypes: {
        fuzzyText: fuzzyTextFilterFn,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      manualSortBy: true,
      disableMultiSort: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  // Listen for changes in pagination and sorting
  useEffect(() => {
    updatePageIndex(pageIndex)
    updatePageSize(pageSize)
    updateSortBy(sortBy)
  }, [
    pageIndex,
    pageSize,
    sortBy,
    updatePageIndex,
    updatePageSize,
    updateSortBy,
  ])

  return (
    <Card>
      <CardHeader>
        <FlexBox>
          {title && <Typography variant="h4">{title}</Typography>}
          <StyledTableActions>
            <Dropdown anchor="center" stayOpen>
              <IconButton dropdownTrigger>
                <Icon
                  path={mdiViewColumn}
                  title="View Columns"
                  size="24px"
                  color={brand}
                />
              </IconButton>

              {allColumns.map(({ id, getToggleHiddenProps, Header }) => {
                if (typeof Header !== 'string') return null // Don't return empty headers which are framgents

                return (
                  <StyledCheckbox key={id} className="checkbox-container">
                    <input
                      type="checkbox"
                      {...getToggleHiddenProps()}
                      id={id}
                    />
                    <Typography
                      variant="bodySmall"
                      component="label"
                      htmlFor={id}
                      colour="pitch"
                    >
                      {Header}
                    </Typography>
                  </StyledCheckbox>
                )
              })}
            </Dropdown>
          </StyledTableActions>
        </FlexBox>
      </CardHeader>
      <CardBody>
        <StyledTable {...getTableProps()}>
          <StyledTableHeader>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledTableHeadCell {...column.getHeaderProps()}>
                    <Typography
                      variant="label"
                      {...column.getSortByToggleProps()}
                      colour="slate"
                    >
                      {column.render('Header')}

                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <Icon
                            path={mdiMenuDown}
                            title="Sort Descending"
                            size="24px"
                            color={mirror}
                          />
                        ) : (
                          <Icon
                            path={mdiMenuDown}
                            title="Sort Descending"
                            size="24px"
                            color={mirror}
                            rotate={180}
                          />
                        ))}
                    </Typography>
                    <StyledFilter>
                      {column.canFilter ? column.render('Filter') : null}
                    </StyledFilter>
                  </StyledTableHeadCell>
                ))}
              </tr>
            ))}
          </StyledTableHeader>
          <tbody {...getTableBodyProps()}>
            {data.length ? (
              <>
                {page.map((row) => {
                  prepareRow(row)
                  return (
                    <StyledTableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <StyledTableDataCell {...cell.getCellProps()}>
                          {isValidElement(cell.value) ? (
                            cell.render('Cell')
                          ) : (
                            <Typography variant="bodySmall">
                              {cell.render('Cell')}
                            </Typography>
                          )}
                        </StyledTableDataCell>
                      ))}
                    </StyledTableRow>
                  )
                })}
              </>
            ) : (
              <>
                {loading ? (
                  <tr>
                    <StyledTableDataCell colSpan={headers.length}>
                      <Loading />
                    </StyledTableDataCell>
                  </tr>
                ) : (
                  <tr>
                    <StyledTableDataCell colSpan={headers.length}>
                      <FlexBox flexDirection="column">
                        <Spacing multiplier={10} />
                        <img
                          src={EmptyTable}
                          alt="Empty Table"
                          height="158px"
                          width="100%"
                        />
                        <Spacing multiplier={4} />
                        <Typography variant="h5" center>
                          Nothing to see here
                        </Typography>
                        <Spacing multiplier={3} />
                        <Typography variant="bodySmall" colour="slate" center>
                          We don't have any information for this table.
                        </Typography>
                        <Spacing multiplier={8} />
                      </FlexBox>
                    </StyledTableDataCell>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </StyledTable>
      </CardBody>

      <CardFooter lite>
        <FlexBox justifyContent="space-between">
          {pageCount > 1 && (
            <Pagination>
              <PaginationButton
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <Icon
                  path={mdiPageFirst}
                  title="First"
                  size="24px"
                  color={pitch}
                />
                <Typography variant="bodySmall">First</Typography>
              </PaginationButton>
              <PaginationButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <Icon
                  path={mdiChevronLeft}
                  title="Prev"
                  size="24px"
                  color={pitch}
                />
                <Typography variant="bodySmall">Prev</Typography>
              </PaginationButton>
              {truncatePagination(pageIndex, pageCount).map((pageNumber) => {
                if (pageNumber === '...') {
                  return (
                    <PaginationButton key={pageNumber - 1} disabled page>
                      <Typography variant="bodySmall">{pageNumber}</Typography>
                    </PaginationButton>
                  )
                }
                return (
                  <PaginationButton
                    key={pageNumber - 1}
                    onClick={() => {
                      gotoPage(pageNumber - 1)
                    }}
                    disabled={pageIndex === pageNumber - 1}
                    active={pageIndex === pageNumber - 1}
                    page
                  >
                    <Typography variant="bodySmall">{pageNumber}</Typography>
                  </PaginationButton>
                )
              })}
              <PaginationButton
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                <Typography variant="bodySmall">Next</Typography>
                <Icon
                  path={mdiChevronLeft}
                  title="First"
                  size="24px"
                  color={pitch}
                  rotate={180}
                />
              </PaginationButton>
              <PaginationButton
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <Typography variant="bodySmall">Last</Typography>
                <Icon
                  path={mdiPageFirst}
                  title="Prev"
                  size="24px"
                  color={pitch}
                  rotate={180}
                />
              </PaginationButton>
            </Pagination>
          )}
          <StyledRowsPerPage>
            <Typography variant="bodySmall" className="rows-text">
              Rows Per Page:
            </Typography>
            <Spacing multiplier={2} />
            <Dropdown>
              <FlexBox dropdownTrigger>
                <button className="page-select" type="button">
                  <Typography variant="bodySmall">{pageSize}</Typography>
                  <Spacing multiplier={1} />
                  <Icon
                    path={mdiMenuDown}
                    title="Dropdown"
                    size="24px"
                    color={pitch}
                  />
                </button>
              </FlexBox>

              {[10, 25, 50, 75, 100].map((pageSize) => (
                <MenuItem
                  key={pageSize}
                  onClick={() => {
                    setPageSize(pageSize)
                  }}
                >
                  <Typography variant="bodySmall">{pageSize}</Typography>
                </MenuItem>
              ))}
            </Dropdown>
          </StyledRowsPerPage>
        </FlexBox>
      </CardFooter>
    </Card>
  )
}
