// This file only contains the stlying for pagination and not a complete pagination component
import styled from 'styled-components'
import { rgba } from 'polished'

export const Pagination = styled.div`
  display: flex;
  align-items: center;
`

export const PaginationButton = styled.button`
  padding: ${({ page }) => (page ? '4px 10px' : '4px')};
  width: 24px;
  height: 24px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: ${({ theme, active }) =>
    active ? theme.colours.azure : 'transparent'};
  border: none;
  outline: none;
  border-radius: 4px;
  margin: 0 1px;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  opacity: ${({ disabled, active }) => (disabled && !active ? '50%' : '100%')};
  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? '' : rgba(theme.colours.pitch, 0.08)};
  }
  p {
    line-height: 23px;
    color: ${({ theme, active }) => (active ? theme.colours.snow : '')};
    &:hover {
      color: ${({ theme, active }) => (active ? theme.colours.snow : '')};
    }
  }
`
