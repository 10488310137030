import React from 'react'
import { Switch } from 'react-router-dom'

import AuthenticatedRoute from '../util/AuthenticatedRoute'
import UnsecureRoute from '../util/UnsecureRoute'

// Pages
import Home from '../pages/Home'
import Login from '../pages/Login'
import ChooseOrganisation from '../pages/ChooseOrganisation'
import ForgottenPassword from '../pages/ForgottenPassword'
import ResetPassword from '../pages/ResetPassword'
import PageNotFound from '../pages/PageNotFound'
import SecureShare from '../pages/secureShare/SecureShare'
import CreateNewLink from '../pages/secureShare/CreateNewLink'
import ShareLink from '../pages/secureShare/ShareLink'
import ViewSecureLink from '../pages/secureShare/ViewSecureLink'
import PeopleList from '../pages/people/PeopleList'
import ViewPerson from '../pages/people/ViewPerson'
import UserView from '../pages/AdminSettings/Users/UserView'
import UserList from '../pages/AdminSettings/Users/UserList'
import NewUser from '../pages/AdminSettings/Users/NewUser'
import AdminSettings from '../pages/AdminSettings/AdminSettings'

const Routes = (
  <Switch>
    <UnsecureRoute path="/login" component={Login} title="Login" />
    <UnsecureRoute
      path="/forgotten-password"
      exact
      component={ForgottenPassword}
      title="Forgotten Password"
    />
    <UnsecureRoute
      path="/choose-organisation"
      exact
      component={ChooseOrganisation}
      title="Choose Organisation"
    />
    <UnsecureRoute
      path="/reset-password/:resetToken"
      exact
      component={ResetPassword}
      title="Reset Password"
    />
    <AuthenticatedRoute path="/" exact component={Home} title="Home" />
    {/* Secure share routes */}
    <AuthenticatedRoute
      path="/secure-share"
      exact
      component={SecureShare}
      title="Secure Share"
    />
    <AuthenticatedRoute
      path="/secure-share/new-link"
      exact
      component={CreateNewLink}
      title="New Secure Share Link"
    />
    <AuthenticatedRoute
      path="/secure-share/share-link/:secureShareId"
      exact
      component={ShareLink}
      title="View Secure Share Link"
    />
    <UnsecureRoute
      path="/secure/:secureShareReference"
      exact
      component={ViewSecureLink}
      title="Daffodil Dashboard Secure Share"
    />
    {/* People */}
    <AuthenticatedRoute
      path="/people"
      exact
      component={PeopleList}
      title="People"
    />
    <AuthenticatedRoute
      path="/people/view/:personId"
      exact
      component={ViewPerson}
      title="View Person"
    />
    {/* Admin Settings */}
    <AuthenticatedRoute
      path="/admin-settings/"
      exact
      component={AdminSettings}
      title="User"
    />
    <AuthenticatedRoute
      path="/admin-settings/users"
      exact
      component={UserList}
      title="User"
    />
    <AuthenticatedRoute
      path="/admin-settings/user/edit/:userId"
      exact
      component={UserView}
      title="Edit User"
    />
    <AuthenticatedRoute
      path="/admin-settings/users/add"
      exact
      component={NewUser}
      title="Add New User"
    />
    {/* 404 */}
    <AuthenticatedRoute component={PageNotFound} title="404" />
  </Switch>
)

export default Routes
