import styled from 'styled-components'

const CardFooter = styled.div`
  padding: ${({ theme, lite }) => {
    if (lite) return `0 ${theme.baseline * 3}px ${theme.baseline * 3}px`

    return `${theme.baseline * 3}px`
  }};
  border-top-color: ${({ theme, lite }) => {
    if (!lite) return `${theme.colours.steel}`
  }};
  border-top-width: ${({ lite }) => {
    if (!lite) return `1px`
  }};
  border-top-style: ${({ lite }) => {
    if (!lite) return `solid`
  }};
`

export default CardFooter
