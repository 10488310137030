import React, { useContext } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Form } from 'react-final-form'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import gql from 'graphql-tag'
import styled, { ThemeContext } from 'styled-components'
import { mdiContentCopy, mdiSend, mdiPlusCircle } from '@mdi/js'
import Icon from '@mdi/react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { isAfter, format } from 'date-fns'
import { useToasts } from 'react-toast-notifications'
import { useModal } from 'react-modal-hook'

import Modal from '../../components/Modal'
import Breadcrumbs from '../../components/Breadcrumbs'
import Title from '../../components/Title'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import CardBody from '../../components/CardBody'
import Typography from '../../components/Typography'
import Spacing from '../../components/Spacing'
import Input from '../../components/Input'
import Select from '../../components/Select'
import CardFooter from '../../components/CardFooter'
import FlexBox from '../../components/FlexBox'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Divider from '../../components/Divider'
import Error from '../../components/Error'
import Activity from '../../components/Activity'
import DropdownLookup from '../../components/DropdownLookup'
import NewPerson from '../../wizards/NewPerson'

const GET_SECURE_SHARE_PAGE_DETAILS = gql`
  query getSecureShareDetails($secureShareId: ID) {
    getSecureShareDetails(secureShareId: $secureShareId) {
      id
      content
      purpose
      passphrase
      selectedExpiry
      expiryDate
      deletionDate
      link
    }
    getEmails(secureShareId: $secureShareId) {
      id
      createdDate
      sentTime
      createdBy {
        id
        fullName
      }
      status
      subject
      targetEmail
      ip
      notes
    }
    getActivities(secureShareId: $secureShareId) {
      id
      time
      content
      user {
        id
        fullName
      }
      ip
    }
  }
`

//this creates an acitivity
const COPY_SECURE_SHARE_LINK = gql`
  mutation copySecureShareLink($secureShareId: ID!) {
    copySecureShareLink(secureShareId: $secureShareId) {
      id
      time
      content
      user {
        id
        fullName
      }
      ip
    }
  }
`

//this creates an email
const SEND_SECURE_SHARE_EMAIL = gql`
  mutation sendSecureShareEmail($email: EmailAddress!, $secureShareId: ID!) {
    sendSecureShareEmail(email: $email, secureShareId: $secureShareId) {
      id
      createdDate
      sentTime
      createdBy {
        id
        fullName
      }
      status
      subject
      targetEmail
      ip
      notes
    }
  }
`

const DELETE_SECURE_SHARE_LINK = gql`
  mutation deleteSecureShareLink($secureShareId: ID!) {
    deleteSecureShareLink(secureShareId: $secureShareId) {
      id
    }
  }
`

const SecureShareLinkInput = styled(Input)`
  max-width: 440px;
  .disabled-select {
    color: ${({ theme }) => theme.colours.azure};
    max-width: 440px;
  }
`

const GET_FILTERED_PEOPLE = gql`
  query getFilteredPeople($filter: String) {
    getFilteredPeople(filter: $filter) {
      id
      fullName
      email {
        emailAddress
      }
    }
  }
`

export default function ShareLink({
  match: {
    params: { secureShareId },
  },
  history,
}) {
  const { addToast } = useToasts()

  const {
    colours: { mirror },
  } = useContext(ThemeContext)

  const { loading, error, data } = useQuery(GET_SECURE_SHARE_PAGE_DETAILS, {
    variables: {
      secureShareId: secureShareId,
    },
    fetchPolicy: 'cache-and-network',
  })

  const [copySecureShareLink, { loading: copySecureShareLinkLoading }] =
    useMutation(COPY_SECURE_SHARE_LINK, {
      onCompleted: () => {
        addToast('Secure Share Link copied to clipboard.', {
          appearance: 'info',
        })
      },
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
      update(cache, { data: { copySecureShareLink } }) {
        const { getActivities, ...rest } = cache.readQuery({
          query: GET_SECURE_SHARE_PAGE_DETAILS,
          variables: {
            secureShareId: secureShareId,
          },
        })
        cache.writeQuery({
          query: GET_SECURE_SHARE_PAGE_DETAILS,
          variables: {
            secureShareId: secureShareId,
          },
          data: {
            getActivities: [...getActivities, copySecureShareLink],
            ...rest,
          },
        })
      },
    })

  const [sendSecureShareEmail, { loading: sendSecureShareEmailLoading }] =
    useMutation(SEND_SECURE_SHARE_EMAIL, {
      onCompleted: ({ sendSecureShareEmail: { targetEmail } }) => {
        addToast(`Secure Share Link emailed to ${targetEmail}`, {
          appearance: 'success',
        })
      },
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
      update(cache, { data: { sendSecureShareEmail } }) {
        const { getEmails, ...rest } = cache.readQuery({
          query: GET_SECURE_SHARE_PAGE_DETAILS,
          variables: {
            secureShareId: secureShareId,
          },
        })

        cache.writeQuery({
          query: GET_SECURE_SHARE_PAGE_DETAILS,
          variables: {
            secureShareId: secureShareId,
          },
          data: {
            getEmails: [...getEmails, sendSecureShareEmail],
            ...rest,
          },
        })
      },
    })

  const [deleteSecureShareLink, { loading: deleteSecureShareLinkLoading }] =
    useMutation(DELETE_SECURE_SHARE_LINK, {
      onCompleted: () => {
        history.push('/secure-share')
        addToast('Secure Share Link deleted.', {
          appearance: 'success',
        })
      },
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
    })

  // queries for sending to people
  const [getFilteredPeople, { data: peopleData }] =
    useLazyQuery(GET_FILTERED_PEOPLE)

  let filteredPeople = peopleData?.getFilteredPeople

  //remove results without email addresses since we can't send to them

  filteredPeople = filteredPeople?.filter((person) => person?.email?.length)

  //make each email address its own row
  filteredPeople = filteredPeople?.map(({ email, fullName }) =>
    email.map(({ emailAddress }) => ({
      emailAddress: emailAddress,
      fullName: fullName,
    }))
  )

  //flatten array to be just a list of people
  filteredPeople = filteredPeople ? [].concat.apply([], filteredPeople) : null

  const [showDeleteModal, hideDeleteModal] = useModal(() => (
    <Modal lite>
      <Card>
        <CardHeader lite>
          <Typography variant="h4" center>
            Delete Secure Share Link
          </Typography>
        </CardHeader>
        <CardBody>
          <Typography variant="bodySmall" center>
            This cannot be undone.
          </Typography>
          <Spacing multiplier={3} />
          <Button
            colour="ruby"
            loading={deleteSecureShareLinkLoading}
            fullwidth
            onClick={() => {
              deleteSecureShareLink({
                variables: {
                  secureShareId,
                },
              })
              hideDeleteModal()
            }}
          >
            Delete Link
          </Button>
          <Spacing multiplier={2} />
          <Typography variant="bodySmall" onClick={hideDeleteModal} center>
            Cancel
          </Typography>
        </CardBody>
      </Card>
    </Modal>
  ))

  //modal for creating a new person
  const [showCreateModal, hideCreateModal] = useModal(() => (
    <Modal>
      <NewPerson hideModal={hideCreateModal} />
    </Modal>
  ))

  if (loading) return <Loading />

  if (error) return <Error error={error} />

  const values = { ...data.getSecureShareDetails }

  //check if link is expired
  const isExpired = isAfter(new Date(), new Date(values.expiryDate))

  //format selected expiry

  switch (values.selectedExpiry) {
    case '1day':
      values.selectedExpiry = '1 Day'
      break
    case '7days':
      values.selectedExpiry = '7 Days'
      break
    case '1month':
      values.selectedExpiry = '1 Month'
      break

    default:
      break
  }

  return (
    <>
      <Breadcrumbs
        items={[
          { label: 'Secure Share', to: '/secure-share' },
          { label: 'Share Link' },
        ]}
      />
      <Title title="Share Link" />

      <Container>
        <Row justifyContent="center">
          <Col col xl="12">
            <Card>
              <CardHeader lite>
                <Typography variant="h5">Sharing Options</Typography>
              </CardHeader>
              <CardBody>
                {!isExpired && (
                  <>
                    <FlexBox>
                      <SecureShareLinkInput
                        name="linkUrl"
                        required
                        label="Link URL"
                        type="text"
                        readOnly
                        value={values.link}
                        disableSelect
                      />
                      <Spacing multiplier={2} />
                      <CopyToClipboard
                        text={values.link}
                        options={{ format: 'text/plain' }}
                      >
                        <Button
                          colour="brand"
                          loading={copySecureShareLinkLoading}
                          onClick={() => {
                            copySecureShareLink({
                              variables: {
                                secureShareId: secureShareId,
                              },
                            })
                          }}
                          icon={
                            <Icon
                              path={mdiContentCopy}
                              title="Copy Secure Link"
                              size="24px"
                              color="white"
                            />
                          }
                        >
                          Copy Link
                        </Button>
                      </CopyToClipboard>
                    </FlexBox>
                    <Form
                      onSubmit={({ email }, form) => {
                        sendSecureShareEmail({
                          variables: {
                            email: email,
                            secureShareId: secureShareId,
                          },
                        }).then(() => {
                          setTimeout(() => {
                            form.resetFieldState('email')
                            form.reset()
                          }) // required to be in a settimeout for some reason
                        })
                      }}
                      render={({ handleSubmit, pristine, invalid }) => (
                        <form
                          onSubmit={handleSubmit}
                          noValidate
                          autoComplete="off"
                        >
                          <FlexBox>
                            <DropdownLookup
                              name="email"
                              label="Send to Contact or Email Address"
                              type="email"
                              required
                              allowCustomInput
                              serverFilterFunction={(filterValue) =>
                                getFilteredPeople({
                                  variables: { filter: filterValue },
                                })
                              }
                              options={filteredPeople?.map(
                                ({ emailAddress, fullName }) => ({
                                  value: emailAddress,
                                  label: emailAddress,
                                  component: (
                                    <>
                                      <Typography variant="bodySmall">
                                        {fullName}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        colour="slate"
                                      >
                                        {emailAddress}
                                      </Typography>
                                    </>
                                  ),
                                })
                              )}
                              additionalMenuItem={
                                <FlexBox onClick={showCreateModal}>
                                  <Icon
                                    path={mdiPlusCircle}
                                    title="Add Person"
                                    size="24px"
                                    color={mirror}
                                  />
                                  <Spacing multiplier={1} />
                                  <Typography
                                    variant="bodySmall"
                                    colour="brand"
                                  >
                                    Add new person
                                  </Typography>
                                </FlexBox>
                              }
                            />
                            <Spacing multiplier={2} />
                            <Button
                              colour="brand"
                              loading={sendSecureShareEmailLoading}
                              disabled={pristine || invalid}
                              type="submit"
                              icon={
                                <Icon
                                  path={mdiSend}
                                  title="Send Email"
                                  size="24px"
                                  color="white"
                                />
                              }
                            >
                              Share Link
                            </Button>
                          </FlexBox>
                        </form>
                      )}
                    />

                    <Divider />
                    <Spacing multiplier={3} />
                    <Typography variant="h5">Details To Share</Typography>
                    <Spacing multiplier={3} />
                  </>
                )}

                <Input
                  name="content"
                  required
                  fullwidth
                  label="Password/Text"
                  type="textarea"
                  rows={6}
                  readOnly
                  value={values.content}
                />
                <Input
                  name="purpose"
                  required
                  label="Purpose (what this link is for)"
                  type="text"
                  readOnly
                  value={values.purpose}
                />
                <Typography variant="h5">Privacy Options</Typography>
                <Spacing multiplier={3} />
                <Input
                  name="passphrase"
                  label="Passphrase"
                  type="text"
                  readOnly
                  value={values.passphrase || ''}
                />
                {isExpired ? (
                  <>
                    <Typography variant="label">Expired</Typography>
                    <Typography variant="bodySmall" colour="slate">
                      {`This link expired on ${format(
                        new Date(values.expiryDate),
                        "dd LLL y 'at' H:mm O"
                      )}`}
                    </Typography>
                  </>
                ) : (
                  <FlexBox>
                    <Select
                      name="expiresIn"
                      label="Expires In"
                      required
                      options={[
                        { value: '1day', label: '1 Day' },
                        { value: '7days', label: '7 Days' },
                        { value: '1month', label: '1 Month' },
                      ]}
                      value={values.selectedExpiry}
                      readOnly
                    />
                    <Spacing multiplier={2} />
                    <Typography variant="bodySmall" colour="slate">
                      {format(
                        new Date(values.expiryDate),
                        "H:mm O 'on' d LLL y"
                      )}
                    </Typography>
                  </FlexBox>
                )}
              </CardBody>
              <CardFooter>
                <FlexBox justifyContent="space-between">
                  <Button colour="ruby" onClick={showDeleteModal}>
                    Delete Link
                  </Button>
                </FlexBox>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <Activity emails={data.getEmails} activities={data.getActivities} />
    </>
  )
}
