import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'react-final-form'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'
import { Container, Row, Col } from 'styled-bootstrap-grid'

import Typography from './../components/Typography'
import Spacing from '../components/Spacing'
import Input from './../components/Input'
import Checkbox from './../components/Checkbox'
import FlexBox from '../components/FlexBox'
import Button from '../components/Button'

//image
import Logo from './../assets/images/logo-full.svg'

const LOGIN = gql`
  mutation loginUser(
    $email: EmailAddress!
    $password: String!
    $rememberMe: Boolean
  ) {
    loginUser(email: $email, password: $password, rememberMe: $rememberMe) {
      id
      organisations {
        id
      }
    }
  }
`

export default function Login({ history }) {
  const [loginUser, { loading, client }] = useMutation(LOGIN, {
    onCompleted: ({ loginUser: { organisations } }) => {
      client.resetStore()

      if (organisations.length === 1) {
        //this person only belongs to one organisation, send them straight to the dashboard and set their organisation cookie as this one
        Cookies.set('ddorg', organisations[0].id)
        history.push('/')
      }
    },
  })

  //redirect user to home page if they try to login whilst already logged in

  if (Cookies.get('dd')) history.push('/')

  return (
    <Fragment>
      <Container>
        <Row justifyContent="center">
          <Col col xl="6">
            <Spacing multiplier={4} />
            <img
              src={Logo}
              alt="Daffodil Dashboard Logo"
              height="56px"
              width="100%"
            />
          </Col>
        </Row>
      </Container>
      <Spacing multiplier={3} />
      <Typography variant="h5" center>
        Enter your login details below
      </Typography>
      <Spacing multiplier={6} />
      <Form
        onSubmit={async ({ email, password, rememberMe }) => {
          const response = await loginUser({
            variables: {
              email: email,
              password: password,
              rememberMe: rememberMe || false,
            },
          }).catch(() => {
            return {
              email: 'Incorrect email or password',
              password: 'Incorrect email or password',
            }
          })

          if (!response.data) return response
        }}
        render={({ handleSubmit, pristine, invalid, dirtySinceLastSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Container>
              <Row justifyContent="center">
                <Col col xl="4">
                  <Input
                    name="email"
                    required
                    fullwidth
                    label="Email Address"
                    type="email"
                    autoComplete
                  />
                </Col>
              </Row>
              <Row justifyContent="center">
                <Col col xl="4">
                  <Input
                    name="password"
                    required
                    fullwidth
                    label="Password"
                    type="password"
                    autoComplete
                  />
                </Col>
              </Row>
              <Row justifyContent="center">
                <Col col xl="4">
                  <FlexBox
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Checkbox name="rememberMe" value="Remember Me" />
                    <Typography
                      variant="label"
                      component={Link}
                      to="forgotten-password"
                    >
                      Forgot My Password
                    </Typography>
                  </FlexBox>
                  <Spacing multiplier={2} />
                </Col>
              </Row>
              <Row justifyContent="center">
                <Col col xl="4">
                  <Button
                    colour="brand"
                    fullwidth
                    loading={loading}
                    disabled={pristine || (invalid && !dirtySinceLastSubmit)}
                    type="submit"
                  >
                    Log In
                  </Button>
                </Col>
              </Row>
            </Container>
          </form>
        )}
      />
    </Fragment>
  )
}
