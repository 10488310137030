import React, { Fragment, useState } from 'react'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'

import { useModal } from 'react-modal-hook'
import { useToasts } from 'react-toast-notifications'

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
} from '@material-ui/core'

import Button from '../../../components/Button'
import Typography from '../../../components/Typography'
import Modal from '../../../components/Modal'

import { Clear } from '@material-ui/icons'

const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: EmailAddress!) {
    requestPasswordReset(email: $email)
  }
`

export default function ResetPassword({ email }) {
  const { addToast } = useToasts()
  const [resetSent, setResetSent] = useState(false)

  const [requestPasswordReset, { loading }] = useMutation(
    REQUEST_PASSWORD_RESET,
    {
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
      onCompleted: () => {
        setResetSent(true)
        hideResetModal()
      },
    }
  )

  const [showResetModal, hideResetModal] = useModal(() => (
    <Modal>
      <Card>
        <CardHeader
          action={
            <IconButton aria-label="Close Pop-up" onClick={hideResetModal}>
              <Clear />
            </IconButton>
          }
          title="Send reset email?"
        />
        <CardContent>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to send this user a password reset email?
          </Typography>
        </CardContent>
        <CardActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            colour="show"
            fullwidth
            variant="outline"
            type="button"
            onClick={hideResetModal}
          >
            Cancel
          </Button>

          <Button
            colour="brand"
            fullwidth
            onClick={() => {
              requestPasswordReset({
                variables: {
                  email: email,
                },
              })
            }}
          >
            Continue
          </Button>
        </CardActions>
      </Card>
    </Modal>
  ))

  return (
    <Fragment>
      {!resetSent ? (
        <Button variant="contained" loading={loading} onClick={showResetModal}>
          Reset Password
        </Button>
      ) : (
        <Typography variant="h5" center>
          Reset email sent
        </Typography>
      )}
    </Fragment>
  )
}
