import React, { useState, useContext } from 'react'
import { Form } from 'react-final-form'
import styled from 'styled-components'
import { rgba, darken } from 'polished'
import { mdiClose } from '@mdi/js'
import Icon from '@mdi/react'
import { ThemeContext } from 'styled-components'

import IconButton from './IconButton'
import FlexBox from './FlexBox'
import Typography from './Typography'
import Spacing from './Spacing'
import Button from './Button'
import Divider from './Divider'

import SaveError from './../assets/images/save-issue.svg'
import Check from './../assets/images/check.svg'

export const Page = styled.div`
  padding: 32px;
  border-top-right-radius: 8px;
  overflow-y: auto;
  .close-button {
    margin-left: auto;
  }
`

const StyledWizard = styled.div`
  background-color: white;
  height: 575px;
  display: flex;
  align-items: stretch;
  border-radius: 8px;
  .nav {
    background: ${({ theme }) =>
      `linear-gradient(180deg, ${theme.colours.brand} 0%, ${darken(
        0.1,
        theme.colours.brand
      )} 100%)`};
    white-space: nowrap;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .nav-links {
      padding-right: 32px;
      .page-name {
        opacity: 32%;
        &.active {
          opacity: 100%;
        }
      }
    }
    button span {
      color: ${({ theme }) => theme.colours.snow};
    }
  }
  .page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;

    hr {
      margin-top: auto;
    }
    .buttons {
      padding: 32px;
      border-bottom-right-radius: 8px;
      .next {
        margin-left: auto;
      }
    }
  }
`

const StyledPageNumber = styled.div`
  cursor: inherit;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: ${({ theme, active, complete }) => {
    if (complete) return theme.colours.lime
    if (!active) return `transparent`

    return rgba(theme.colours.snow, 0.24)
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ theme, active, complete }) => {
    if (active || complete) return `solid 3px transparent`

    return `solid 3px ${rgba(theme.colours.snow, 0.24)}`
  }};
  background-image: ${({ complete }) => (complete ? `url(${Check})` : '')};
  background-position: center;
  background-size: 75%;
  background-repeat: no-repeat;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.24);
  p {
    line-height: 23px;
    display: ${({ complete }) => (complete ? 'none' : '')};
  }
`

export default function Wizard({
  children,
  initialValues,
  onSubmit,
  loading,
  name,
  cancelFunction,
  ...rest
}) {
  const [pageNumber, setPageNumber] = useState(0)
  const [values, setValues] = useState(initialValues || {})
  const [showConfirmaion, setShowConfirmation] = useState(false)
  const [submitError, setSubmitError] = useState(null)
  const [completedPages, setCompletedPages] = useState([])

  const themeContext = useContext(ThemeContext)

  const { brand } = themeContext.colours

  //get pages to show in wizards
  const pages = React.Children.toArray(children).filter(
    ({ props: { confirmation } }) => !confirmation
  )
  const confirmationPage = React.Children.toArray(children).find(
    ({ props: { confirmation } }) => confirmation
  )

  const validate = (values) =>
    pages[pageNumber].props.validate
      ? pages[pageNumber].props.validate(values)
      : {}

  const submitFunction = (values) => {
    setCompletedPages([...completedPages, pageNumber])
    if (pageNumber === pages.length - 1) {
      return onSubmit(values)
        .then(() => {
          setShowConfirmation(true)
        })
        .catch((error) => {
          setSubmitError(error)
        })
    } else {
      setPageNumber(pageNumber + 1)
      setValues(values)
    }
  }

  const activePage = pages[pageNumber]

  const isLastPage = pageNumber === pages.length - 1

  //calculate all pageNumber names to display on the left of the wizard
  const pageNames = pages.map(({ props: { title } }) => title)

  return (
    <Form
      initialValues={values}
      validate={validate}
      onSubmit={submitFunction}
      {...rest}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <StyledWizard>
            <div className="nav">
              <div>
                <Typography variant="h4" colour="snow">
                  {name}
                </Typography>
                <Spacing multiplier={2} />
                {pageNames.map((pageName, index) => (
                  <FlexBox key={index} className="nav-links">
                    <StyledPageNumber
                      active={
                        pageNumber === index &&
                        !!!showConfirmaion &&
                        !!!submitError
                      }
                      complete={completedPages.includes(index)}
                    >
                      <Typography variant="caption" colour="snow">
                        {index + 1}
                      </Typography>
                    </StyledPageNumber>
                    <Spacing multiplier={1} />
                    <Typography
                      variant="navigation"
                      colour="snow"
                      className={`page-name ${
                        pageNumber === index &&
                        !!!showConfirmaion &&
                        !!!submitError
                          ? 'active'
                          : ''
                      }`}
                    >
                      {pageName}
                    </Typography>
                  </FlexBox>
                ))}
              </div>
              {!showConfirmaion && !submitError && (
                <Button
                  colour="show"
                  fullwidth
                  variant="outline"
                  type="button"
                  onClick={cancelFunction}
                >
                  Cancel
                </Button>
              )}
            </div>
            <div className="page-content">
              {!showConfirmaion && !submitError ? (
                <>
                  {activePage}
                  <Divider />

                  <FlexBox className="buttons">
                    {pageNumber > 0 && (
                      <Button
                        colour="brand"
                        variant="outline"
                        type="button"
                        onClick={() => {
                          setPageNumber(pageNumber - 1)
                        }}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      colour="brand"
                      type="submit"
                      loading={loading}
                      className="next"
                    >
                      {!isLastPage ? 'Next' : 'Save'}
                    </Button>
                  </FlexBox>
                </>
              ) : (
                <>
                  {showConfirmaion ? (
                    confirmationPage
                  ) : (
                    <Page>
                      <FlexBox flexDirection="column">
                        <IconButton
                          className="close-button"
                          onClick={cancelFunction}
                        >
                          <Icon
                            path={mdiClose}
                            title="Close"
                            size="24px"
                            color={brand}
                          />
                        </IconButton>
                        <Spacing multiplier={4} />
                        <img
                          src={SaveError}
                          alt="Error"
                          height="176px"
                          width="100%"
                        />
                        <Spacing multiplier={4} />
                        <Typography variant="h4" center>
                          We encountered a problem
                        </Typography>
                        <Spacing multiplier={1} />
                        <Typography variant="bodySmall" colour="slate" center>
                          Click below to try again.
                        </Typography>
                        <Spacing multiplier={2} />
                        <Button colour="brand" type="submit" loading={loading}>
                          Try Again
                        </Button>
                        <Spacing multiplier={9} />
                        <Typography variant="caption" colour="slate" center>
                          If this issue persists contact our support team at{' '}
                          <Typography
                            variant="caption"
                            component="a"
                            href="mailto:support@daffodil-software.co.uk"
                            center
                            colour="brand"
                          >
                            support@daffodil-software.co.uk
                          </Typography>
                        </Typography>
                      </FlexBox>
                    </Page>
                  )}
                </>
              )}
            </div>
          </StyledWizard>
        </form>
      )}
    </Form>
  )
}
