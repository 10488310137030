import React, { Fragment } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Form } from 'react-final-form'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { format, add } from 'date-fns'
import { useToasts } from 'react-toast-notifications'

import Breadcrumbs from '../../components/Breadcrumbs'
import Title from '../../components/Title'
import Card from '../../components/Card'
import CardBody from '../../components/CardBody'
import Typography from '../../components/Typography'
import Spacing from '../../components/Spacing'
import Input from '../../components/Input'
import Select from '../../components/Select'
import CardFooter from '../../components/CardFooter'
import FlexBox from '../../components/FlexBox'
import Button from '../../components/Button'
import CardHeader from '../../components/CardHeader'

const CREATE_SECURE_SHARE_LINK = gql`
  mutation createSecureShareLink(
    $content: String!
    $purpose: String!
    $passphrase: String
    $expiresIn: String!
  ) {
    createSecureShareLink(
      content: $content
      purpose: $purpose
      passphrase: $passphrase
      expiresIn: $expiresIn
    ) {
      id
    }
  }
`

export default function CreateNewLink({ history }) {
  const { addToast } = useToasts()

  const [createSecureShareLink, { loading }] = useMutation(
    CREATE_SECURE_SHARE_LINK,
    {
      onCompleted: ({ createSecureShareLink }) => {
        history.push(`/secure-share/share-link/${createSecureShareLink.id}`)
      },
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
    }
  )

  return (
    <Fragment>
      <Breadcrumbs
        items={[
          { label: 'Secure Share', to: '/secure-share' },
          { label: 'Create New Link' },
        ]}
      />
      <Title title="Create New Link" />

      <Container>
        <Row justifyContent="center">
          <Col col xl="12">
            <Form
              onSubmit={async ({ content, purpose, passphrase, expiresIn }) => {
                createSecureShareLink({
                  variables: {
                    content,
                    purpose,
                    passphrase,
                    expiresIn,
                  },
                })
              }}
              render={({ handleSubmit, pristine, invalid, values }) => {
                //calcilate how much time needs adding to show expected xpiry date

                let expiryDate

                switch (values.expiresIn) {
                  case '1day':
                    expiryDate = add(new Date(), {
                      days: 1,
                    })
                    break
                  case '7days':
                    expiryDate = add(new Date(), {
                      days: 7,
                    })
                    break
                  case '1month':
                    expiryDate = add(new Date(), {
                      months: 1,
                    })
                    break

                  default:
                    break
                }

                return (
                  <form onSubmit={handleSubmit} noValidate autoComplete="off">
                    <Card>
                      <CardHeader lite>
                        <Typography variant="h5">Details To Share</Typography>
                      </CardHeader>
                      <CardBody>
                        <Input
                          name="content"
                          required
                          fullwidth
                          label="Password/Text"
                          type="textarea"
                          rows={6}
                        />
                        <Input
                          name="purpose"
                          required
                          label="Purpose (what this link is for)"
                          type="text"
                        />
                        <Typography variant="h5">Privacy Options</Typography>
                        <Spacing multiplier={3} />
                        <Input
                          name="passphrase"
                          label="Passphrase"
                          type="text"
                        />
                        <FlexBox>
                          <Select
                            name="expiresIn"
                            label="Expires In"
                            required
                            options={[
                              { value: '1day', label: '1 Day' },
                              { value: '7days', label: '7 Days' },
                              { value: '1month', label: '1 Month' },
                            ]}
                          />
                          <Spacing multiplier={2} />
                          <Typography variant="bodySmall" colour="slate">
                            {!!expiryDate &&
                              format(expiryDate, "H:mm O 'on' d LLL y")}
                          </Typography>
                        </FlexBox>
                      </CardBody>
                      <CardFooter>
                        <FlexBox justifyContent="space-between">
                          <Button
                            colour="brand"
                            variant="outline"
                            component={Link}
                            to="/secure-share"
                          >
                            Cancel
                          </Button>
                          <Button
                            colour="brand"
                            loading={loading}
                            disabled={pristine || invalid}
                            type="submit"
                          >
                            Create Link
                          </Button>
                        </FlexBox>
                      </CardFooter>
                    </Card>
                  </form>
                )
              }}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
