import React from 'react'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import { useToasts } from 'react-toast-notifications'
import { useModal } from 'react-modal-hook'

import Button from '../../components/Button'
import Typography from '../../components/Typography'
import MenuItem from '../../components/MenuItem'
import Modal from '../../components/Modal'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import CardBody from '../../components/CardBody'
import Spacing from '../../components/Spacing'

const DELETE_SECURE_SHARE_LINK = gql`
  mutation deleteSecureShareLink($secureShareId: ID!) {
    deleteSecureShareLink(secureShareId: $secureShareId) {
      id
    }
  }
`

const GET_SECURE_SHARE_LINKS = gql`
  query getSecureShareLinks($filter: String) {
    getSecureShareLinks(filter: $filter) {
      id
      createdBy {
        id
        fullName
      }
      createdDate
      link
      purpose
      expiryDate
    }
  }
`

export default function DeleteLink({ secureShareId, activeTab }) {
  const { addToast } = useToasts()

  const [deleteSecureShareLink, { loading: deleteSecureShareLinkLoading }] =
    useMutation(DELETE_SECURE_SHARE_LINK, {
      onCompleted: () => {
        addToast('Secure Share Link deleted.', {
          appearance: 'success',
        })
      },
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
      update(cache, { data: { deleteSecureShareLink } }) {
        const { getSecureShareLinks } = cache.readQuery({
          query: GET_SECURE_SHARE_LINKS,
          variables: {
            filter: activeTab === 'Active Links' ? 'active' : 'expired',
          },
        })

        cache.writeQuery({
          query: GET_SECURE_SHARE_LINKS,
          variables: {
            filter: activeTab === 'Active Links' ? 'active' : 'expired',
          },
          data: {
            getSecureShareLinks: getSecureShareLinks.filter(
              (shareLink) => shareLink.id !== deleteSecureShareLink.id
            ),
          },
        })
      },
    })

  const [showDeleteModal, hideDeleteModal] = useModal(() => (
    <Modal lite>
      <Card>
        <CardHeader lite>
          <Typography variant="h4" center>
            Delete Secure Share Link
          </Typography>
        </CardHeader>
        <CardBody>
          <Typography variant="bodySmall" center>
            This cannot be undone.
          </Typography>
          <Spacing multiplier={3} />
          <Button
            colour="ruby"
            loading={deleteSecureShareLinkLoading}
            fullwidth
            onClick={() => {
              deleteSecureShareLink({
                variables: {
                  secureShareId,
                },
              })
              hideDeleteModal()
            }}
          >
            Delete Link
          </Button>
          <Spacing multiplier={2} />
          <Typography variant="bodySmall" onClick={hideDeleteModal} center>
            Cancel
          </Typography>
        </CardBody>
      </Card>
    </Modal>
  ))

  return (
    <MenuItem disabled={deleteSecureShareLinkLoading} onClick={showDeleteModal}>
      <Typography
        variant="bodySmall"
        colour={deleteSecureShareLinkLoading ? 'mirror' : undefined}
      >
        Delete Link
      </Typography>
    </MenuItem>
  )
}
