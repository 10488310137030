import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/browser'

import Loading from '../components/Loading'

import PageWrapper from './PageWrapper'

const AUTHENTICATE_USER = gql`
  query getUser {
    getUser {
      id
      roleId
      email
      fullName
      picture
      organisations {
        id
      }
    }
  }
`

const GET_ORGANISATION = gql`
  query getOrganisation {
    getOrganisation {
      id
      postcodeLookup
      defaultPhoneCountry
      defaultAddressCountry
    }
  }
`

export default function AuthenticatedRoute({
  component: Component,
  title,
  ...props
}) {
  const { loading, error, data } = useQuery(AUTHENTICATE_USER, {
    fetchPolicy: 'network-only',
  })

  const {
    loading: organistionLoading,
    error: organisationError,
    data: organisationData,
  } = useQuery(GET_ORGANISATION, {
    fetchPolicy: 'network-only',
    skip: !Cookies.get('ddorg'),
  })

  if (error || organisationError || !Cookies.get('dd')) {
    return <Redirect to="/login" />
  }

  // if (!Cookies.get('ddorg')) {
  //   //user doesn't have a selected organisation, send them to the choose organisation page, this page handles if they only have one organisation
  //   return <Redirect to="/choose-organisation" />
  // }

  if (organisationError) {
    // remove organisation cookie because it might have been set wrong
    Cookies.remove('ddorg')
  }

  //user exists, add them to sentry

  if (data) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: data.getUser.id,
        roleId: data.getUser.roleId,
        username: data.getUser.fullName,
        email: data.getUser.email,
      })
    })
  }

  return (
    <Route
      {...props}
      render={(innerProps) => (
        <PageWrapper
          user={data?.getUser}
          organisation={organisationData?.getOrganisation}
        >
          <Helmet>
            <title>{title}</title>
          </Helmet>
          {loading || organistionLoading ? (
            <Loading />
          ) : (
            <Component {...innerProps} />
          )}
        </PageWrapper>
      )}
    />
  )
}
