import React, { useContext } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { FieldArray } from 'react-final-form-arrays'
import { mdiClose, mdiPlusCircle } from '@mdi/js'
import Icon from '@mdi/react'
import styled, { ThemeContext } from 'styled-components'
import arrayMutators from 'final-form-arrays'

import TabbedModal, {
  Tab,
  TabFooter,
  TabContent,
} from '../../components/TabbedModal'
import { Form } from 'react-final-form'
import FlexBox from '../../components/FlexBox'
import Button from '../../components/Button'
import Select from '../../components/Select'
import Input from '../../components/Input'
import Loading from '../../components/Loading'
import Card from '../../components/Card'
import Error from '../../components/Error'
import { validatePhoneNumber, getCountryNames } from '../../util/functions'
import DropdownLookup from '../../components/DropdownLookup'
import Spacing from '../../components/Spacing'
import IconButton from '../../components/IconButton'
import Typography from '../../components/Typography'
import AddressInput from '../../components/AddressInput'
import { OrganisationContext } from '../../util/PageWrapper'
import { parsePhoneNumberFromString } from 'libphonenumber-js/min'
import { useToasts } from 'react-toast-notifications'
import Modal from '../../components/Modal'
import { Container, Row, Col } from 'styled-bootstrap-grid'

const UPDATE_PERSON = gql`
  mutation updatePerson(
    $personId: ID!
    $title: String!
    $firstName: String!
    $lastName: String!
    $phoneNumbers: [JSONObject]
    $emailAddresses: [JSONObject]
    $addressLine1: String
    $addressLine2: String
    $city: String
    $county: String
    $postcode: String
    $addressCountry: String
  ) {
    updatePerson(
      personId: $personId
      title: $title
      firstName: $firstName
      lastName: $lastName
      phoneNumbers: $phoneNumbers
      emailAddresses: $emailAddresses
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      county: $county
      postcode: $postcode
      addressCountry: $addressCountry
    ) {
      id
      fullName
      title
      firstName
      lastName
      phone {
        isPrimary
        number
      }
      email {
        isPrimary
        emailAddress
      }
      address {
        addressLine1
        addressLine2
        city
        county
        country
        postcode
      }
    }
  }
`

const GET_PERSON = gql`
  query getPerson($personId: ID!) {
    getPerson(personId: $personId) {
      id
      title
      firstName
      lastName
      phone {
        isPrimary
        number
      }
      email {
        isPrimary
        emailAddress
      }
      address {
        addressLine1
        addressLine2
        city
        county
        country
        postcode
      }
    }
  }
`

const RemoveRow = styled.div`
  margin-bottom: 29px;
  display: flex;
`

export default function EditPerson({ personId, closeModal, onComplete }) {
  const {
    colours: { brand },
  } = useContext(ThemeContext)

  const { defaultPhoneCountry, defaultAddressCountry } =
    useContext(OrganisationContext)

  const { addToast } = useToasts()

  const {
    loading: getPersonLoading,
    error: getPersonError,
    data: getPersonData,
  } = useQuery(GET_PERSON, {
    variables: {
      personId: personId,
    },
  })

  const [updatePerson, { loading: updatePersonLoading }] = useMutation(
    UPDATE_PERSON,
    {
      onError: () => {
        addToast('Something went wrong, please try again.', {
          appearance: 'error',
        })
      },
      onCompleted: ({ updatePerson: { fullName } }) => {
        onComplete()
        addToast(`${fullName} updated`, {
          appearance: 'success',
        })
        closeModal()
      },
    }
  )

  if (getPersonLoading) return <Loading />

  if (getPersonError)
    return (
      <Modal>
        <Container>
          <Row justifyContent="center">
            <Col xl={9}>
              <Card>
                <Error error={getPersonError} />
              </Card>
            </Col>
          </Row>
        </Container>
      </Modal>
    )

  //format data for use in forms
  const { email, phone, address, ...rest } = getPersonData.getPerson

  const formattedEmails = email
    ?.sort((x, y) => y.isPrimary - x.isPrimary)
    .map(({ emailAddress }) => ({
      email: emailAddress,
    }))

  const formattedPhoneNumbers = phone
    ?.sort((x, y) => y.isPrimary - x.isPrimary)
    .map(({ number }) => {
      const parsedNumber = parsePhoneNumberFromString(number)
      return {
        phoneCountryCode: parsedNumber.country,
        phoneNumber: parsedNumber
          .format('IDD', {
            fromCountry: parsedNumber.country,
            humanReadable: true,
          })
          .replace(' ', ''),
      }
    })

  const initialValues = {
    ...rest,
    ...address,
    phoneNumbers: phone
      ? formattedPhoneNumbers
      : [{ phoneCountryCode: defaultPhoneCountry }],
    emailAddresses: email ? formattedEmails : [{ email: null }],
    addressCountry: address?.country || defaultAddressCountry,
  }

  const countriesList = getCountryNames()

  //format countries list for dropdown
  const formattedCountryList = countriesList.map(({ code, name }) => ({
    value: code,
    label: name,
  }))

  return (
    <Modal>
      <Container>
        <Row justifyContent="center">
          <Col xl={9}>
            <TabbedModal name="Edit Details">
              <Tab title="Name">
                <Form
                  initialValues={initialValues}
                  onSubmit={({
                    title,
                    firstName,
                    lastName,
                    phoneNumbers,
                    emailAddresses,
                    addressLine1,
                    addressLine2,
                    city,
                    county,
                    postcode,
                    addressCountry,
                  }) =>
                    updatePerson({
                      variables: {
                        personId,
                        title,
                        firstName,
                        lastName,
                        phoneNumbers,
                        emailAddresses,
                        addressLine1,
                        addressLine2,
                        city,
                        county,
                        postcode,
                        addressCountry,
                      },
                    })
                  }
                >
                  {({ handleSubmit, pristine }) => (
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                      <TabContent>
                        <Select
                          name="title"
                          label="Title"
                          required
                          small
                          options={[
                            { value: 'Miss', label: 'Miss' },
                            { value: 'Mrs', label: 'Mrs' },
                            { value: 'Ms', label: 'Ms' },
                            { value: 'Dr', label: 'Dr' },
                            { value: 'Mr', label: 'Mr' },
                          ]}
                        />
                        <Input
                          name="firstName"
                          required
                          label="First Name"
                          type="text"
                          fullwidth
                        />
                        <Input
                          name="lastName"
                          required
                          label="Last Name"
                          type="text"
                          fullwidth
                        />
                      </TabContent>

                      <TabFooter>
                        <FlexBox justifyContent="space-between">
                          <Button
                            colour="brand"
                            variant="outline"
                            type="button"
                            onClick={() => {
                              closeModal()
                            }}
                          >
                            Cancel
                          </Button>

                          <Button
                            colour="brand"
                            type="submit"
                            updatePersonLoading={updatePersonLoading}
                            disabled={pristine}
                          >
                            Save Changes
                          </Button>
                        </FlexBox>
                      </TabFooter>
                    </form>
                  )}
                </Form>
              </Tab>
              <Tab title="Personal Details">
                <Form
                  initialValues={initialValues}
                  mutators={{
                    ...arrayMutators,
                  }}
                  onSubmit={({
                    title,
                    firstName,
                    lastName,
                    phoneNumbers,
                    emailAddresses,
                    addressLine1,
                    addressLine2,
                    city,
                    county,
                    postcode,
                    addressCountry,
                  }) =>
                    updatePerson({
                      variables: {
                        personId,
                        title,
                        firstName,
                        lastName,
                        phoneNumbers,
                        emailAddresses,
                        addressLine1,
                        addressLine2,
                        city,
                        county,
                        postcode,
                        addressCountry,
                      },
                    })
                  }
                  validate={({ phoneNumbers, emailAddresses }) => {
                    const errors = {}

                    const enteredNumbers = phoneNumbers.map(
                      ({ phoneNumber }) => phoneNumber
                    )

                    const duplicateNumbers = enteredNumbers.reduce(
                      (acc, v, i, arr) =>
                        arr.indexOf(v) !== i && acc.indexOf(v) === -1
                          ? acc.concat(v)
                          : acc,
                      []
                    ) // https://stackoverflow.com/questions/840781/get-all-non-unique-values-i-e-duplicate-more-than-one-occurrence-in-an-array

                    errors.phoneNumbers = phoneNumbers.map(
                      ({ phoneNumber, phoneCountryCode }) => {
                        if (phoneCountryCode) {
                          //check if phone number has already been enetered
                          if (duplicateNumbers.includes(phoneNumber)) {
                            return {
                              phoneNumber: 'Duplicate number',
                            }
                          }
                          return {
                            phoneNumber: validatePhoneNumber(
                              phoneNumber,
                              phoneCountryCode
                            ),
                          }
                        } else if (phoneNumber) {
                          //number entered but no country selected
                          return {
                            phoneCountryCode: 'Invalid Country',
                          }
                        }
                        return undefined
                      }
                    )

                    //check emails haven't been entered twice
                    const enteredEmails = emailAddresses.map(
                      ({ email }) => email
                    )
                    const duplicateEmails = enteredEmails.reduce(
                      (acc, v, i, arr) =>
                        arr.indexOf(v) !== i && acc.indexOf(v) === -1
                          ? acc.concat(v)
                          : acc,
                      []
                    ) // https://stackoverflow.com/questions/840781/get-all-non-unique-values-i-e-duplicate-more-than-one-occurrence-in-an-array

                    errors.emailAddresses = emailAddresses.map(({ email }) => {
                      //check if email has already been enetered
                      if (email && duplicateEmails.includes(email)) {
                        return {
                          email: 'Duplicate email',
                        }
                      }
                      return undefined
                    })

                    return errors
                  }}
                >
                  {({ handleSubmit, pristine }) => (
                    <form onSubmit={handleSubmit} noValidate autoComplete="off">
                      <TabContent>
                        <FieldArray name="phoneNumbers">
                          {({ fields }) => (
                            <>
                              {fields.map((name, index) => (
                                <FlexBox key={name} alignItems="flex-end">
                                  <DropdownLookup
                                    name={`${name}.phoneCountryCode`}
                                    label={index ? '' : 'Phone Number'}
                                    options={formattedCountryList}
                                  />
                                  <Spacing multiplier={2} />
                                  <Input
                                    name={`${name}.phoneNumber`}
                                    type="number"
                                    fullwidth
                                  />

                                  {fields.length > 1 && (
                                    <RemoveRow>
                                      <Spacing multiplier={2} />
                                      <IconButton
                                        onClick={() => fields.remove(index)}
                                      >
                                        <Icon
                                          path={mdiClose}
                                          title="Delete Row"
                                          size="24px"
                                          color={brand}
                                        />
                                      </IconButton>
                                    </RemoveRow>
                                  )}
                                </FlexBox>
                              ))}
                              <FlexBox
                                inline
                                onClick={() =>
                                  fields.push({
                                    phoneCountryCode: defaultPhoneCountry,
                                  })
                                }
                              >
                                <Icon
                                  path={mdiPlusCircle}
                                  title="Delete Row"
                                  size="20px"
                                  color={brand}
                                />
                                <Spacing multiplier={1} />
                                <Typography variant="bodySmall" colour="brand">
                                  Add another number
                                </Typography>
                              </FlexBox>
                            </>
                          )}
                        </FieldArray>
                        <Spacing multiplier={3} />
                        <FieldArray name="emailAddresses">
                          {({ fields }) => (
                            <>
                              {fields.map((name, index) => (
                                <FlexBox key={name} alignItems="flex-end">
                                  <Input
                                    name={`${name}.email`}
                                    type="email"
                                    fullwidth
                                    label={index ? '' : 'Email Address'}
                                  />

                                  {fields.length > 1 && (
                                    <RemoveRow>
                                      <Spacing multiplier={2} />
                                      <IconButton
                                        onClick={() => fields.remove(index)}
                                      >
                                        <Icon
                                          path={mdiClose}
                                          title="Delete Row"
                                          size="24px"
                                          color={brand}
                                        />
                                      </IconButton>
                                    </RemoveRow>
                                  )}
                                </FlexBox>
                              ))}
                              <FlexBox
                                inline
                                onClick={() => fields.push({ email: null })}
                              >
                                <Icon
                                  path={mdiPlusCircle}
                                  title="Delete Row"
                                  size="20px"
                                  color={brand}
                                />
                                <Spacing multiplier={1} />
                                <Typography variant="bodySmall" colour="brand">
                                  Add another email
                                </Typography>
                              </FlexBox>
                            </>
                          )}
                        </FieldArray>
                        <Spacing multiplier={4} />
                        <Typography variant="h5">Home Address</Typography>
                        <Spacing multiplier={3} />
                        <AddressInput />
                      </TabContent>

                      <TabFooter>
                        <FlexBox justifyContent="space-between">
                          <Button
                            colour="brand"
                            variant="outline"
                            type="button"
                            onClick={() => {
                              closeModal()
                            }}
                          >
                            Cancel
                          </Button>

                          <Button
                            colour="brand"
                            updatePersonLoading={updatePersonLoading}
                            type="submit"
                            disabled={pristine}
                          >
                            Save Changes
                          </Button>
                        </FlexBox>
                      </TabFooter>
                    </form>
                  )}
                </Form>
              </Tab>
            </TabbedModal>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}
