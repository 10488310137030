import React, { Fragment } from 'react'
import styled from 'styled-components'
import Typography from './Typography'
import FlexBox from './FlexBox'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import Spacing from './Spacing'

const Tab = styled.div`
  padding-bottom: 8px;
  border-bottom: ${({ theme, active }) => {
    if (active) return `solid 2px ${theme.colours.brand}`

    return `solid 2px transparent`
  }};
  cursor: pointer;
`

export default function Tabs({ tabs }) {
  return (
    <Container>
      <Row justifyContent="center">
        <Col col xl="12">
          <FlexBox>
            {tabs.map((tab) => (
              <Fragment key={tab.label}>
                <Tab active={tab.active} onClick={tab.onClick}>
                  <Typography
                    variant="navigation"
                    colour={tab.active ? 'brand' : 'slate'}
                    semiBold
                  >
                    {tab.label}
                  </Typography>
                </Tab>
                <Spacing multiplier={4} />
              </Fragment>
            ))}
          </FlexBox>
          <Spacing multiplier={5} />
        </Col>
      </Row>
    </Container>
  )
}
