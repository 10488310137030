import React from 'react'
import { Router } from 'react-router-dom'
import { InMemoryCache, ApolloProvider, ApolloClient } from '@apollo/client'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'
import Cookies from 'js-cookie'
import WebFont from 'webfontloader'
import * as Sentry from '@sentry/browser'
import { ThemeProvider } from 'styled-components'
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid'
import { HelmetProvider } from 'react-helmet-async'
import { ToastProvider } from 'react-toast-notifications'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import theme from './assets/ThemeStyling'
import GlobalStyling from './assets/GlobalStyling'

import Routes from './routes'
import Toast from './components/Toast'

// connect to sentry for error tracking
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  environment: process.env.REACT_APP_SENTRY_ENV,
})

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          if (error.extensions.code === 'UNAUTHENTICATED') {
            Cookies.remove('dd')
            Cookies.remove('ddorg')
          } else if (error.extensions.code !== 'BAD_USER_INPUT') {
            Sentry.captureException(error.message)
          }

          //only log errors if not on live
          if (process.env.REACT_APP_SENTRY_ENV !== 'live')
            console.error(`[GraphQl error]: ${error.message}`)
        })
      }
      if (networkError && process.env.REACT_APP_SENTRY_ENV !== 'live')
        console.error(`[Network error]: ${networkError}`)
    }),
    createUploadLink({
      uri: `${process.env.REACT_APP_SERVER_URL}/graphql`,
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache(),
})

const history = createBrowserHistory()

ReactGA.initialize(process.env.REACT_APP_GA, {
  testMode: process.env.REACT_APP_SENTRY_ENV === 'local',
})

// record initial load page view
ReactGA.pageview(window.location.pathname)

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }) // Update the user's current page
  ReactGA.pageview(location.pathname) // Record a pageview for the given page
})

// load webfonts
WebFont.load({
  google: {
    families: ['Roboto:400', 'Poppins:400,500,600'],
  },
})

export default function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <BaseCSS />
        <GlobalStyling />
        <ApolloProvider client={client}>
          <ToastProvider
            components={{ Toast: Toast }}
            autoDismissTimeout={6000}
            autoDismiss
            transitionDuration={300}
          >
            <GridThemeProvider gridTheme={theme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Router history={history}>{Routes}</Router>
              </MuiPickersUtilsProvider>
            </GridThemeProvider>
          </ToastProvider>
        </ApolloProvider>
      </ThemeProvider>
    </HelmetProvider>
  )
}
