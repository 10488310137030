import React, { useContext } from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { Helmet } from 'react-helmet-async'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'

import Error from '../../components/Error'
import Breadcrumbs from '../../components/Breadcrumbs'
import Button from '../../components/Button'
import Title from '../../components/Title'
import Typography from '../../components/Typography'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import CardBody from '../../components/CardBody'
import Spacing from '../../components/Spacing'
import Loading from '../../components/Loading'

import AddDetails from './../../assets/images/add-details.svg'

import { OrganisationContext } from './../../util/PageWrapper'
import { getFormattedStringAddress } from '../../util/functions'
import FlexBox from '../../components/FlexBox'

import EditPerson from './EditPerson'
import { useModal } from 'react-modal-hook'
import Activity from '../../components/Activity'

const GET_PERSON = gql`
  query getPersonDetails($personId: ID!) {
    getPerson(personId: $personId) {
      id
      fullName
      phone {
        isPrimary
        number
      }
      email {
        isPrimary
        emailAddress
      }
      address {
        addressLine1
        addressLine2
        city
        county
        country
        postcode
      }
    }
    getEmails(personId: $personId) {
      id
      createdDate
      sentTime
      createdBy {
        id
        fullName
      }
      status
      subject
      targetEmail
      ip
      notes
    }
    getActivities(personId: $personId) {
      id
      time
      content
      user {
        id
        fullName
      }
      ip
    }
  }
`

export default function ViewPerson({
  match: {
    params: { personId },
  },
}) {
  const { defaultPhoneCountry } = useContext(OrganisationContext)

  const [showUpdateModal, hideUpdateModal] = useModal(() => (
    <EditPerson
      personId={personId}
      closeModal={hideUpdateModal}
      onComplete={refetch}
    />
  ))

  const {
    loading: getPersonLoading,
    error: getPersonError,
    data: getPersonData,
    refetch,
  } = useQuery(GET_PERSON, {
    variables: {
      personId: personId,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (getPersonLoading) return <Loading />

  if (getPersonError) return <Error error={getPersonError} />

  const { fullName, phone, email, address } = getPersonData?.getPerson

  //sort values so is primary is at the top
  let formattedNumbers = phone?.sort((x, y) => y.isPrimary - x.isPrimary) || []
  formattedNumbers = formattedNumbers.map(({ number }) =>
    parsePhoneNumberFromString(number).format('IDD', {
      fromCountry: defaultPhoneCountry,
      humanReadable: true,
    })
  )

  // sort emails
  const sortedEmail = email?.sort((x, y) => y.isPrimary - x.isPrimary) || []

  const formattedAddress = getFormattedStringAddress(address)

  return (
    <>
      <Helmet>
        <title>{fullName}</title>
      </Helmet>
      <Breadcrumbs
        items={[
          { label: 'Contacts' },
          { label: 'People', to: '/people' },
          { label: fullName },
        ]}
      />
      <Title title={fullName} />
      <Container>
        <Row>
          <Col col xl="4">
            <Card>
              <CardHeader lite>
                <Typography variant="h4">Basic Details</Typography>
              </CardHeader>
              <CardBody>
                {!!!formattedNumbers.length &&
                !!!sortedEmail.length &&
                !!!formattedAddress ? (
                  <FlexBox flexDirection="column" alignItems="center">
                    <Spacing multiplier={9} />
                    <img
                      src={AddDetails}
                      alt="Add Details"
                      height="110px"
                      width="144px"
                    />
                    <Spacing multiplier={4} />
                    <Typography variant="bodySmall" colour="slate" center>
                      Click the button below to record basic details for this
                      person
                    </Typography>
                    <Spacing multiplier={8} />
                    <Button
                      colour="brand"
                      fullwidth
                      type="button"
                      onClick={showUpdateModal}
                    >
                      Add Details
                    </Button>
                  </FlexBox>
                ) : (
                  <>
                    {!!formattedNumbers.length && (
                      <>
                        <Typography variant="label">Phone Numbers</Typography>
                        {formattedNumbers.map((number, index) => (
                          <Typography
                            variant="bodySmall"
                            key={index}
                            component="a"
                            href={`tel:${number}`}
                            colour="brand"
                          >
                            {number}
                          </Typography>
                        ))}
                        <Spacing multiplier={3} />
                      </>
                    )}
                    {!!sortedEmail.length && (
                      <>
                        <Typography variant="label">Email Addresses</Typography>
                        {sortedEmail.map(({ emailAddress }, index) => (
                          <Typography
                            variant="bodySmall"
                            key={index}
                            component="a"
                            href={`mailto:${emailAddress}`}
                            colour="brand"
                          >
                            {emailAddress}
                          </Typography>
                        ))}
                        <Spacing multiplier={3} />
                      </>
                    )}
                    {!!formattedAddress && (
                      <>
                        <Typography variant="label">Address</Typography>
                        <Typography variant="bodySmall">
                          {formattedAddress}
                        </Typography>
                        <Spacing multiplier={3} />
                      </>
                    )}
                    <Button
                      colour="brand"
                      fullwidth
                      type="button"
                      onClick={showUpdateModal}
                    >
                      Edit Details
                    </Button>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Activity
        emails={getPersonData.getEmails}
        activities={getPersonData.getActivities}
      />
    </>
  )
}
