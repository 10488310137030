import React, { useContext, Fragment } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { mdiMenuDown } from '@mdi/js'
import Icon from '@mdi/react'
import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'
import { useHistory, useLocation, Link } from 'react-router-dom'
import SmallLogo from './../assets/images/logo-small.svg'
import SettingsIcon from '@mui/icons-material/Settings'

import { UserContext } from './../util/PageWrapper'

import UserIcon from './UserIcon'
import Dropdown from './Dropdown'
import MenuItem from './MenuItem'
import Typography from './Typography'
import FlexBox from './FlexBox'

//header height is calculated from the baseline

const StyledHeader = styled.div`
  min-height: ${({ theme }) => `${theme.baseline * 9}px`};
  background-color: ${({ theme }) => theme.colours.brand};
  margin-bottom: ${({ theme, path }) => {
    //if on login page, return higher pixel amount, else its multiple of 3
    if (
      path.includes('/login') ||
      path.includes('/forgotten-password') ||
      path.includes('/reset-password')
    )
      return `${theme.baseline * 8}px`

    return `${theme.baseline * 3}px`
  }};
  padding: 0 24px;
  display: flex;
`

const VerticalDivider = styled.div`
  height: ${({ theme }) => `${theme.baseline * 5}px`};
  width: 1px;
  background-color: ${({ theme }) => `${theme.colours.chalk}`};
  opacity: 10%;
  margin: 0 8px 0 16px;
  align-self: center;
`

const NavigationLink = styled(Link)`
  padding: 0 16px;
  display: flex;
  align-items: center;
  transition: all ease-in-out 150ms;
  text-decoration: none;
  white-space: nowrap;
  height: ${({ as }) => (as === 'div' ? '100%' : '')};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => darken(0.08, theme.colours.brand)};
  }
`

const UserAccount = styled.div`
  text-decoration: none;
  padding: 0 8px 0 16px;
  display: flex;
  align-items: center;
  transition: all ease-in-out 150ms;
  height: 100%;
  white-space: nowrap;
  &:hover {
    background-color: ${({ theme }) => darken(0.08, theme.colours.brand)};
    cursor: pointer;
  }
  svg {
    margin-left: 4px;
  }
  img {
    margin-right: 12px;
  }
`
//need to use this for positioning of the user account with a dropdown
const UserAccountWrapper = styled.div`
  margin-left: auto;
  display: flex;
`

const LOGOUT = gql`
  mutation logoutUser {
    logoutUser
  }
`

export default function Header() {
  const user = useContext(UserContext)

  const history = useHistory()
  const { pathname } = useLocation()

  const [logoutUser, { loading, client }] = useMutation(LOGOUT, {
    onCompleted: () => {
      Cookies.remove('dd')
      Cookies.remove('ddorg')
      client.clearStore()
      history.push('/login')
    },
  })

  return (
    <StyledHeader path={pathname}>
      {user ? (
        <Fragment>
          <Link to="/">
            <img
              src={SmallLogo}
              alt="Daffodil Dashboard Small Logo"
              height="100%"
              width="24px"
            />
          </Link>
          <VerticalDivider />
          <NavigationLink to="/">
            <Typography variant="navigation" colour="snow">
              Dashboard
            </Typography>
          </NavigationLink>
          <NavigationLink to="/secure-share">
            <Typography variant="navigation" colour="snow">
              Secure Share
            </Typography>
          </NavigationLink>
          <div>
            <Dropdown anchor="right">
              <NavigationLink as="div" dropdownTrigger>
                <Typography variant="navigation" colour="snow">
                  Contacts
                </Typography>
                <Icon
                  path={mdiMenuDown}
                  title="Contacts"
                  size="24px"
                  color="white"
                />
              </NavigationLink>
              <MenuItem component={Link} to="/people">
                <Typography variant="bodySmall">People</Typography>
              </MenuItem>
            </Dropdown>
          </div>

          <UserAccountWrapper>
            {user.roleId >= 5 && (
              <NavigationLink to="/admin-settings">
                <SettingsIcon style={{ fill: 'white' }} />
                <Typography variant="navigation" colour="snow">
                  Admin Settings
                </Typography>
              </NavigationLink>
            )}

            <Dropdown anchor="right">
              <UserAccount dropdownTrigger>
                <UserIcon size="28px" src={user.picture} />
                <Typography variant="navigation" colour="snow">
                  {user.fullName}
                </Typography>
                <Icon
                  path={mdiMenuDown}
                  title="User Profile"
                  size="24px"
                  color="white"
                />
              </UserAccount>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  logoutUser()
                }}
              >
                <Typography
                  variant="bodySmall"
                  colour={loading ? 'mirror' : undefined}
                >
                  Logout
                </Typography>
              </MenuItem>
            </Dropdown>
          </UserAccountWrapper>
        </Fragment>
      ) : (
        <FlexBox>
          <img
            src={SmallLogo}
            alt="Daffodil Dashboard Small Logo"
            height="100%"
            width="24px"
          />
        </FlexBox>
      )}
    </StyledHeader>
  )
}
