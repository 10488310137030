import styled from 'styled-components'

//header height is calculated from the baseline

const Spacing = styled.span`
  display: block;
  min-height: ${({ multiplier, theme }) => `${theme.baseline * multiplier}px`};
  min-width: ${({ multiplier, theme }) => `${theme.baseline * multiplier}px`};
`

export default Spacing
