import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import validator from 'validator'
import { rgba } from 'polished'

import Icon from '@mdi/react'
import { mdiAlertCircleOutline } from '@mdi/js'

import Typography from './Typography'
import FlexBox from './FlexBox'

export const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ fullwidth, type }) => {
    if (type === 'postcode' && !fullwidth) return '200px'

    if (!fullwidth) return '330px'
  }};
  .disabled-select {
    user-select: none;
  }
  input,
  textarea,
  .disabled-select {
    resize: none;
    width: 100%;
    max-width: ${({ fullwidth }) => {
      if (!fullwidth) return '330px'
    }};
    height: ${({ rows }) => {
      if (!rows) return '40px'
    }};
    border-width: 1px;
    border-style: solid;
    transition: all ease-in-out 150ms;
    border-color: ${({ theme, error }) => {
      if (error) return theme.colours.ruby

      return theme.colours.steel
    }};
    border-radius: 4px;
    background-color: ${({ theme, readOnly, disabled }) => {
      if (readOnly || disabled) return rgba(theme.colours.pitch, 0.04)

      return theme.colours.snow
    }};
    padding: ${({ error }) => {
      if (error) return '7px 40px 7px 16px'

      return '7px 16px'
    }};
    font-family: ${({ theme }) => theme.typography.secondary};
    font-size: 16px;
    line-height: 26px;
    color: ${({ theme, readOnly, disabled }) => {
      if (readOnly || disabled) return theme.colours.slate

      return theme.colours.pitch
    }};
    cursor: ${({ disableSelect }) => {
      if (disableSelect) return 'not-allowed'
    }};
    margin-bottom: 24px;
    &:active,
    &:focus {
      border-color: ${({ theme, readOnly, disabled }) => {
        if (!readOnly && !disabled) return theme.colours.brand
      }};
      outline: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px ${({ theme }) => theme.colours.snow} inset !important;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .input-container {
    position: relative;
    svg {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
  .optional-text {
    margin-left: 4px;
  }
  .helper-text {
    margin-top: -24px;
  }
`

export default function Input({
  required,
  name,
  label,
  type,
  helperText,
  fullwidth,
  value,
  disableSelect,
  validate: inputValidation,
  autoComplete,
  ...rest
}) {
  const theme = useContext(ThemeContext)

  const [showPassword, setShowPassword] = useState(false)

  //validation function, returns error message if theres a problem

  const validate = (value) => {
    //required validator

    if (required && (!value || !value.trim())) {
      return 'Required'
    }

    if (inputValidation) return inputValidation(value)

    //validate based on type
    switch (type) {
      //email validation
      case 'email':
        if (
          value &&
          (!validator.isEmail(value) ||
            value.includes('.con') ||
            value.includes('.coma'))
        ) {
          return 'Invalid Email'
        }

        break

      default:
        break
    }
  }

  // calculate actual type to use for inout type
  let actualType

  switch (type) {
    case 'email':
    case 'password':
    case 'number':
      actualType = type

      break

    case 'postcode':
    default:
      actualType = 'text'
      break
  }

  // if this input is set at view only, dont generate the form controls with it

  if (rest.readOnly)
    return (
      <StyledInput
        {...rest}
        disableSelect={disableSelect}
        fullwidth={fullwidth ? 1 : 0}
      >
        <FlexBox justifyContent="space-between">
          <FlexBox>
            <Typography variant="label">{label}</Typography>
            {!required && (
              <Typography variant="label" className="optional-text">
                (Optional)
              </Typography>
            )}
          </FlexBox>
          {type === 'password' && (
            <Typography
              variant="label"
              onClick={() => {
                setShowPassword(!showPassword)
              }}
            >
              {showPassword ? 'Hide' : 'Show'}
            </Typography>
          )}
        </FlexBox>
        <div className="input-container">
          {type === 'textarea' ? (
            <textarea value={value} {...rest} />
          ) : !!disableSelect ? (
            <div
              {...rest}
              className="disabled-select"
              type={showPassword ? 'text' : actualType}
            >
              {value}
            </div>
          ) : (
            <input
              value={value}
              {...rest}
              type={showPassword ? 'text' : actualType}
            />
          )}
        </div>
        {!!helperText ? (
          <Typography variant="caption" colour="slate" className="helper-text">
            {helperText}
          </Typography>
        ) : null}
      </StyledInput>
    )

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        //get error

        let errorMessage

        if (meta.error && meta.touched) {
          errorMessage = meta.error
        } else if (!!meta.submitError && !meta.dirtySinceLastSubmit) {
          errorMessage = meta.submitError
        }

        return (
          <StyledInput
            {...rest}
            fullwidth={fullwidth ? 1 : 0}
            error={!!errorMessage}
            type={type}
          >
            <FlexBox justifyContent="space-between">
              <FlexBox>
                <Typography variant="label">{label}</Typography>
                {!required && label && (
                  <Typography variant="label" className="optional-text">
                    (Optional)
                  </Typography>
                )}
              </FlexBox>
              {type === 'password' && (
                <Typography
                  variant="label"
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </Typography>
              )}
            </FlexBox>
            <div className="input-container">
              {type === 'textarea' ? (
                <textarea {...input} {...rest} />
              ) : (
                <input
                  {...input}
                  {...rest}
                  type={showPassword ? 'text' : actualType}
                  autoComplete={autoComplete ? 'on' : 'off'} // Needed to disable autocomplete
                />
              )}

              {!!errorMessage && type !== 'textarea' && (
                <Icon
                  path={mdiAlertCircleOutline}
                  title="Error"
                  size="24px"
                  color={theme.colours.ruby}
                />
              )}
            </div>
            {!!errorMessage ? (
              <Typography
                variant="caption"
                colour="ruby"
                className="helper-text"
              >
                {errorMessage}
              </Typography>
            ) : !!helperText ? (
              <Typography
                variant="caption"
                colour="slate"
                className="helper-text"
              >
                {helperText}
              </Typography>
            ) : null}
          </StyledInput>
        )
      }}
    </Field>
  )
}
