import React from 'react'

import { Row, Col } from 'styled-bootstrap-grid'

import Input from '../../../components/Input'
import Multiselect from '../../../components/Multiselect'
import Select from '../../../components/Select'

export default function UserEdit({ orgs }) {
  return (
    <>
      <Row>
        <Col md="5">
          <Input name="firstName" required label="First Name" type="text" />
        </Col>
      </Row>
      <Row>
        <Col md="5">
          <Input name="lastName" required label="Last Name" type="text" />
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <Input
            name="email"
            required
            fullwidth
            label="Email Address"
            type="email"
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <Select
            name="roleId"
            label="Role"
            required
            options={[
              { value: '1', label: 'User' },
              { value: '5', label: 'Admin' },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col md="6">
          <Multiselect
            name="organisations"
            label="Organisations"
            options={orgs}
            required
          />
        </Col>
      </Row>
    </>
  )
}
