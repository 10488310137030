import React, { useState, useContext } from 'react'
import { mdiPlusCircle, mdiAccount, mdiDotsVertical } from '@mdi/js'
import Icon from '@mdi/react'
import { useQuery } from '@apollo/client'
import { useModal } from 'react-modal-hook'
import gql from 'graphql-tag'
import { ThemeContext } from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { Link } from 'react-router-dom'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'

import Table from '../../components/Table'
import Breadcrumbs from '../../components/Breadcrumbs'
import Button from '../../components/Button'
import Title from '../../components/Title'
import Error from '../../components/Error'
import Modal from '../../components/Modal'
import FlexBox from '../../components/FlexBox'
import Spacing from '../../components/Spacing'
import Typography from '../../components/Typography'
import Dropdown from '../../components/Dropdown'
import MenuItem from '../../components/MenuItem'
import IconButton from '../../components/IconButton'

import NewPerson from '../../wizards/NewPerson'

import { OrganisationContext } from './../../util/PageWrapper'

const GET_PEOPLE = gql`
  query getPeople($pageIndex: Int, $pageSize: Int, $sortBy: [JSONObject]) {
    getPeople(pageIndex: $pageIndex, pageSize: $pageSize, sortBy: $sortBy) {
      people {
        id
        phone {
          number
          isPrimary
        }
        email {
          emailAddress
          isPrimary
        }
        fullName
      }
      pageCount
      totalRecords
    }
  }
`

export default function PeopleList() {
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [sortBy, setSortBy] = useState([])

  const {
    colours: { mirror, slate },
  } = useContext(ThemeContext)

  const { defaultPhoneCountry } = useContext(OrganisationContext)

  // get all persons for this organisation

  const { loading, error, data, refetch } = useQuery(GET_PEOPLE, {
    variables: {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
    },
    fetchPolicy: 'network-only',
  })

  //modal for creating a new person
  const [showCreateModal, hideCreateModal] = useModal(() => (
    <Modal>
      <NewPerson hideModal={hideCreateModal} onComplete={refetch} />
    </Modal>
  ))

  if (error) return <Error error={error} />

  let tableData = []

  if (data) {
    tableData = data.getPeople.people.map((person) => {
      //format numbers
      let phoneNumber
      let secondaryNumbers = []

      if (person?.phone?.length) {
        const { number } = person.phone.find(
          (phoneNumber) => phoneNumber.isPrimary
        )
        //format numbers into local format
        phoneNumber = parsePhoneNumberFromString(number).format('IDD', {
          fromCountry: defaultPhoneCountry,
          humanReadable: true,
        })

        secondaryNumbers =
          person.phone.filter((phoneNumber) => !phoneNumber.isPrimary) || []

        secondaryNumbers = secondaryNumbers.map(({ number }) =>
          parsePhoneNumberFromString(number).format('IDD', {
            fromCountry: defaultPhoneCountry,
            humanReadable: true,
          })
        )
      }

      // format emails

      let email
      let secondaryEmails = []

      if (person?.email?.length) {
        const { emailAddress } = person.email.find(
          (emailAddress) => emailAddress.isPrimary
        )

        email = emailAddress

        secondaryEmails =
          person.email.filter((emailAddress) => !emailAddress.isPrimary) || []

        secondaryEmails = secondaryEmails.map(
          ({ emailAddress }) => emailAddress
        )
      }

      return {
        fullName: (
          <FlexBox>
            <Icon path={mdiAccount} title="Person" size="24px" color={mirror} />
            <Spacing multiplier={1} />
            <Typography variant="bodySmall">{person.fullName}</Typography>
          </FlexBox>
        ),
        phoneNumber: (
          <FlexBox inline>
            <Typography
              variant="bodySmall"
              component="a"
              href={`tel:${phoneNumber}`}
              colour="brand"
              nowrap
            >
              {phoneNumber}
            </Typography>
            <Spacing multiplier={1} />
            {!!secondaryNumbers.length && (
              <Dropdown anchor="center">
                <Typography
                  variant="bodySmall"
                  colour="brand"
                  clickable
                  dropdownTrigger
                >
                  {`(+${secondaryNumbers.length})`}
                </Typography>
                {secondaryNumbers.map((number, index) => (
                  <MenuItem key={index} component="a" href={`tel:${number}`}>
                    <Typography variant="bodySmall" colour="brand">
                      {number}
                    </Typography>
                  </MenuItem>
                ))}
              </Dropdown>
            )}
          </FlexBox>
        ),
        emailAddress: (
          <FlexBox inline>
            <Typography
              variant="bodySmall"
              component="a"
              href={`mailto:${email}`}
              colour="brand"
              nowrap
            >
              {email}
            </Typography>
            <Spacing multiplier={1} />
            {!!secondaryEmails.length && (
              <Dropdown anchor="center">
                <Typography
                  variant="bodySmall"
                  colour="brand"
                  clickable
                  dropdownTrigger
                  nowrap
                >
                  {`(+${secondaryEmails.length})`}
                </Typography>
                {secondaryEmails.map((emailAddress, index) => (
                  <MenuItem
                    key={index}
                    component="a"
                    href={`mailto:${emailAddress}`}
                  >
                    <Typography variant="bodySmall" colour="brand" nowrap>
                      {secondaryEmails}
                    </Typography>
                  </MenuItem>
                ))}
              </Dropdown>
            )}
          </FlexBox>
        ),
        actions: (
          <Dropdown anchor="right">
            <IconButton dropdownTrigger>
              <Icon
                path={mdiDotsVertical}
                title="Secure Share Link Settings"
                size="24px"
                color={slate}
              />
            </IconButton>

            <MenuItem component={Link} to={`/people/view/${person.id}`}>
              <Typography
                variant="bodySmall"
                nowrap
              >{`View ${person.fullName}`}</Typography>
            </MenuItem>
          </Dropdown>
        ),
      }
    })
  }

  return (
    <>
      <Breadcrumbs items={[{ label: 'Contacts' }, { label: 'People' }]} />
      <Title
        title={`${
          data?.getPeople?.totalRecords ? data?.getPeople?.totalRecords : ''
        } People`}
        lite
        button={
          <Button
            colour="brand"
            icon={
              <Icon
                path={mdiPlusCircle}
                title="Add New Person"
                size="24px"
                color="white"
              />
            }
            onClick={showCreateModal}
          >
            Add New Person
          </Button>
        }
      />
      <Container>
        <Row justifyContent="center">
          <Col col xl="12">
            <Table
              headers={[
                {
                  Header: 'Name',
                  accessor: 'fullName',
                  disableFilters: true,
                },
                {
                  Header: 'Phone Number',
                  accessor: 'phoneNumber',
                  disableFilters: true,
                  disableSortBy: true,
                },
                {
                  Header: 'Email Address',
                  accessor: 'emailAddress',
                  disableSortBy: true,
                  disableFilters: true,
                },
                {
                  accessor: 'actions',
                  disableSortBy: true,
                  disableFilters: true,
                },
              ]}
              data={tableData}
              loading={loading}
              updatePageIndex={setPageIndex}
              updatePageSize={setPageSize}
              pageSize={pageSize}
              pageIndex={pageIndex}
              sortBy={sortBy}
              updateSortBy={setSortBy}
              pageCount={data?.getPeople?.pageCount}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}
