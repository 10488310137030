import React, { useState } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import Typography from './Typography'
import Spacing from './Spacing'
import Divider from './Divider'

export const Tab = styled.div`
  border-top-right-radius: 8px;
  overflow-y: auto;
  flex-grow: 1;
  & > * {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

const StyledTabbed = styled.div`
  background-color: white;
  height: 575px;
  display: flex;
  align-items: stretch;
  border-radius: 8px;
  .nav {
    white-space: nowrap;
    background: ${({ theme }) => theme.colours.chalk};
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
    flex-direction: column;
    min-width: 240px;
    border-right: ${({ theme }) =>
      `solid 1px ${rgba(theme.colours.mirror, 0.4)}`};
    h4 {
      padding: 24px;
    }
  }
  .page-content {
    width: 100%;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    flex-direction: column;

    .tab-header {
      padding: 24px;
    }
  }
`

const TabName = styled.div`
  cursor: pointer;
  padding: 0 32px;
  p {
    line-height: 48px;
  }
  &.active {
    border-left: ${({ theme }) => `solid 4px ${theme.colours.azure}`};
    padding-left: 28px;
    background: ${({ theme }) => rgba(theme.colours.azure, 0.1)};
  }
`

export const TabContent = styled.div`
  padding: 24px;
`

export const TabFooter = styled.div`
  padding: 24px;
  border-top: ${({ theme }) => `solid 1px ${rgba(theme.colours.mirror, 0.4)}`};
  margin-top: auto;
`

export default function TabbedModal({ children, name }) {
  const [pageNumber, setPageNumber] = useState(0)

  //get pages to show in wizards
  const pages = React.Children.toArray(children)

  let activePage = pages[pageNumber]

  //calculate all pageNumber names to display on the left of the wizard
  const pageNames = pages.map(({ props: { title } }) => title)

  return (
    <StyledTabbed>
      <div className="nav">
        <Typography variant="h4" colour="pitch">
          {name}
        </Typography>
        <Divider />
        <Spacing multiplier={3} />
        {pageNames.map((pageName, index) => (
          <TabName
            key={pageName}
            onClick={() => {
              setPageNumber(index)
            }}
            className={index === pageNumber ? 'active' : ''}
          >
            <Typography variant="bodySmall" colour="pitch">
              {pageName}
            </Typography>
          </TabName>
        ))}
      </div>
      <div className="page-content">
        <Typography variant="h4" colour="pitch" className="tab-header">
          {pageNames[pageNumber]}
        </Typography>
        <Divider />
        {activePage}
      </div>
    </StyledTabbed>
  )
}
