import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import Typography from './Typography'

import check from './../assets/images/check.svg'

export const StyledCheckbox = styled.div`
  margin-bottom: ${({ theme }) => `${theme.baseline * 2}px`};
  display: flex;
  align-items: center;
  input {
    transition: background-color linear 50ms;
    height: 20px;
    width: 20px;
    margin: 0;
    appearance: none;
    background-color: ${({ theme }) => `${theme.colours.snow}`};
    border: 1px solid #e9edf1;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ error, theme }) => {
      if (error) return theme.colours.ruby

      return theme.colours.mirror
    }};
    border-radius: 2px;
    margin-right: ${({ theme }) => `${theme.baseline}px`};
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:checked {
      background-color: ${({ theme }) => `${theme.colours.brand}`};
      border-color: ${({ theme }) => `${theme.colours.brand}`};
      background-image: url(${check});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 80%;
    }
  }
  label {
    cursor: pointer;
    margin-right: ${({ theme }) => `${theme.baseline}px`};
  }
`

export default function Checkbox({
  required,
  name,
  value,
  helperText,
  ...rest
}) {
  const validate = (value) => {
    if (required && !value) {
      return 'Required'
    }
  }

  return (
    <Field name={name} type="checkbox" validate={validate}>
      {({ input, meta }) => {
        //get error

        let errorMessage

        if (meta.error && meta.touched) {
          errorMessage = meta.error
        } else if (!!meta.submitError && !meta.dirtySinceLastSubmit) {
          errorMessage = meta.submitError
        }

        return (
          <StyledCheckbox {...rest} error={!!errorMessage}>
            <input type="checkbox" {...input} id={name} />
            <Typography
              variant="bodySmall"
              component="label"
              htmlFor={name}
              colour={!!errorMessage ? 'ruby' : 'pitch'}
            >
              {value}
            </Typography>
            {!!errorMessage ? (
              <Typography variant="bodySmall" colour="ruby">
                {`(${errorMessage})`}
              </Typography>
            ) : !!helperText ? (
              <Typography variant="bodySmall" colour="slate">
                {`(${helperText})`}
              </Typography>
            ) : null}
          </StyledCheckbox>
        )
      }}
    </Field>
  )
}
